exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n#createOpenShiftForm .edit-dept-allowed .sitter-toggle, #createOpenShiftForm .edit-dept-allowed .payroll-picker {\n  margin-top: 6px;\n}\n#createOpenShiftForm .date-chip .v-chip__content {\n  text-shadow: none !important;\n}\n#createOpenShiftForm .create-open-shift {\n  bottom: 32px;\n  margin-bottom: 0px !important;\n  position: absolute;\n  right: 32px;\n}\n#createOpenShiftForm .notify-error {\n  border: 2px solid #E74C3C !important;\n}\n#createOpenShiftForm .payroll-picker {\n  margin-top: 12px;\n}\n#createOpenShiftForm .payroll-picker .payroll-date-icon .fa-calendar {\n  font-size: 16px;\n  margin-left: -1px;\n}\n#createOpenShiftForm .payroll-picker .payroll-date-icon .fa-dollar-sign {\n  position: absolute;\n  left: 10px;\n  font-size: 9px;\n  top: 6px;\n}\n#createOpenShiftForm .row.no-gutters {\n  margin-bottom: 16px;\n}\n#createOpenShiftForm .shift-select .v-select__selections span {\n  white-space: nowrap !important;\n  overflow: hidden !important;\n  text-overflow: ellipsis !important;\n}\n#createOpenShiftForm .shift-select .v-select__selections input {\n  display: none;\n}\n#createOpenShiftForm .shift-separator {\n  margin-top: 8px;\n  text-align: center;\n}\n#createOpenShiftForm .sitter-toggle {\n  padding-left: 10px;\n}\n#createOpenShiftForm .v-select .v-input__slot {\n  padding-right: 4px;\n}\n#createOpenShiftForm .v-input--radio-group label {\n  font-size: 14px;\n}\n#createOpenShiftForm .v-input--switch {\n  margin-top: 12px;\n}\n#createOpenShiftForm .v-input--switch .v-input--selection-controls__input {\n  margin-right: 0px;\n}\n#createOpenShiftForm .v-input--switch .v-messages {\n  margin-left: 48px;\n}\n#createOpenShiftForm .v-input--switch .v-messages .v-messages__message {\n  color: #9e9e9e;\n  font-size: 12px !important;\n}\n#createOpenShiftForm .v-select--chips .v-select__selections {\n  margin-top: 4px !important;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};