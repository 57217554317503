<template>
  <v-container
    v-if="schedulePeriods.length === 0"
    class="schedule"
    fluid
    fill-height
  >
    <portal to="page-title">
      <v-container class="schedule-header py-0 mx-0">
        <v-row justify="space-between">
          <v-col
            align-self="center"
            class="pl-0"
            cols="auto"
          >
            <v-row
              align="center"
              class="ml-1"
            >
              <DepartmentSelector />
            </v-row>
          </v-col>
          <v-spacer />
        </v-row>
      </v-container>
    </portal>
    <v-row
      justify="center"
      style="margin-top: -150px"
    >
      <v-col
        md="6"
        sm="12"
      >
        <div class="mt-10 mx-3 pa-6">
          <v-row justify="center">
            <v-img
              contain
              max-width="50%"
              src="@/assets/images/oops-penguin.svg"
            />
          </v-row>
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="darken-2 grey--text mt-5 px-5 text--darken-3 text-center "
            v-html="$t('descriptions.noSchedules')"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
  <v-container
    v-else
    class="schedule"
    fluid
  >
    <template v-if="retrievingSchedule && !gridInitialized && showSkeletonLoader">
      <v-container
        class="schedule"
        fluid
      >
        <v-row dense>
          <template v-if="$vuetify.breakpoint.smAndDown">
            <v-col
              v-for="n in 2"
              :key="n"
              cols="auto"
            >
              <v-skeleton-loader
                class="single-action"
                type="actions"
              />
            </v-col>
          </template>
          <template v-else>
            <v-col
              v-for="n in 5"
              :key="n"
              cols="auto"
            >
              <v-skeleton-loader
                class="single-action"
                type="actions"
              />
            </v-col>
          </template>
          <v-spacer />
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-skeleton-loader
              class="no-action"
              type="table"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-else-if="!retrievingSchedule">
      <v-container
        class="schedule"
        fluid
        :style="gridMaxWidth"
      >
        <portal to="page-title">
          <template v-if="$vuetify.breakpoint.width < 600">
            <v-container class="schedule-header py-0">
              <v-row justify="space-between">
                <v-col
                  align-self="center"
                  class="pl-0"
                  cols="auto"
                >
                  <v-row
                    align="center"
                    class="ml-1"
                  >
                    <DepartmentSelector />
                  </v-row>
                </v-col>
                <v-spacer />
                <v-col
                  v-if="!scheduleIsReadOnly || scheduleIsReadyToPublish || needsReview"
                  align-self="center"
                  class="px-0"
                  cols="auto"
                >
                  <v-menu
                    min-width="200"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-1 account-icon"
                        icon
                        small
                        v-on="on"
                      >
                        <v-icon>
                          fal fa-ellipsis-v
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-if="scheduleIsInitial && $store.state.org.settings.scheduling.nurseSelfSchedule.alwaysOpen"
                        class="caption"
                        :disabled="postingScheduleForSelfSchedule"
                        :title="$t('labels.postForSelfSchedule')"
                        @click.prevent="postScheduleForSelfSchedule"
                      >
                        <v-list-item-action>
                          <v-progress-circular
                            v-if="postingScheduleForSelfSchedule"
                            color="primary lighten-2"
                            indeterminate
                            size="16"
                            width="2"
                          />
                          <v-icon
                            v-else
                            small
                          >
                            fal fa-calendar-edit
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-title>
                          {{ $t('labels.postForSelfSchedule') }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="scheduleIsDraft"
                        class="caption"
                        :disabled="postingSchedule"
                        :title="$t('labels.postDraft')"
                        @click.prevent="postSchedule"
                      >
                        <v-list-item-action>
                          <v-progress-circular
                            v-if="postingSchedule"
                            color="primary lighten-2"
                            indeterminate
                            size="16"
                            width="2"
                          />
                          <v-icon
                            v-else
                            small
                          >
                            fal fa-paper-plane
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-title>
                          {{ $t('labels.postDraft') }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="scheduleIsReadyToPublish"
                        class="caption"
                        :disabled="publishingSchedule"
                        :title="$t('labels.publishSchedule')"
                        @click.prevent="publishSchedule"
                      >
                        <v-list-item-action>
                          <v-progress-circular
                            v-if="publishingSchedule"
                            color="primary lighten-2"
                            indeterminate
                            size="16"
                            width="2"
                          />
                          <v-icon
                            v-else
                            small
                          >
                            fal fa-paper-plane
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-title>
                          {{ $t('labels.publishSchedule') }}
                        </v-list-item-title>
                      </v-list-item>
                      <template v-if="needsReview">
                        <v-list-item
                          class="caption"
                          :disabled="approvingSchedule || rejectingSchedule"
                          :title="$t('labels.reject')"
                          @click.prevent="rejectSchedule"
                        >
                          <v-list-item-action>
                            <v-progress-circular
                              v-if="rejectingSchedule"
                              color="primary lighten-2"
                              indeterminate
                              size="16"
                              width="2"
                            />
                            <v-icon
                              v-else
                              color="error"
                              small
                            >
                              fas fa-times-circle
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-title>
                            {{ $t('labels.reject') }}
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          class="caption"
                          :disabled="approvingSchedule || rejectingSchedule"
                          :title="$t('labels.approve')"
                          @click.prevent="approveSchedule"
                        >
                          <v-list-item-action>
                            <v-progress-circular
                              v-if="approvingSchedule"
                              color="primary lighten-2"
                              indeterminate
                              size="16"
                              width="2"
                            />
                            <v-icon
                              v-else
                              color="success"
                              small
                            >
                              fas fa-check-circle
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-title>
                            {{ $t('labels.approve') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                      <v-list-item
                        @click="toggleHelpPanel"
                      >
                        <v-list-item-action>
                          <v-icon small>
                            fal fa-info-circle
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-title>
                          {{ $t('labels.information') }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col
                  v-else
                  align-self="center"
                  class="px-0"
                  cols="auto"
                >
                  <v-menu
                    min-width="200"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mx-1"
                        icon
                        small
                        v-on="on"
                      >
                        <v-icon>
                          fal fa-ellipsis-v
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        @click="toggleHelpPanel"
                      >
                        <v-list-item-action>
                          <v-icon small>
                            fal fa-info-circle
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-title>
                          {{ $t('labels.information') }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-else>
            <v-row
              class="schedule-header"
              justify="space-between"
            >
              <v-col
                align-self="center"
                cols="auto"
              >
                <v-row
                  align="center"
                  class="ml-1"
                >
                  <DepartmentSelector />
                </v-row>
              </v-col>
              <v-col
                v-if="!scheduleIsReadOnly || scheduleIsReadyToPublish || needsReview"
                align-self="center"
                cols="auto"
              >
                <v-tooltip
                  v-if="scheduleIsInitial && $store.state.org.settings.scheduling.nurseSelfSchedule.alwaysOpen"
                  bottom
                  nudge-top="10"
                >
                  <template #activator="{ on: tooltipOn, attrs }">
                    <v-btn
                      class="post-draft mr-2"
                      color="secondary"
                      :disabled="postingScheduleForSelfSchedule"
                      :text="$vuetify.breakpoint.lgAndUp"
                      :icon="$vuetify.breakpoint.mdAndDown"
                      :title="$t('labels.postForSelfSchedule')"
                      v-bind="attrs"
                      v-on="{...tooltipOn, 'click': postScheduleForSelfSchedule}"
                    >
                      <v-progress-circular
                        v-if="postingScheduleForSelfSchedule"
                        color="primary lighten-2"
                        indeterminate
                        size="22"
                        width="2"
                      />
                      <v-icon
                        v-else
                        size="20"
                      >
                        fas fa-calendar-edit
                      </v-icon>
                      <span
                        v-if="$vuetify.breakpoint.lgAndUp"
                        class="ml-2"
                      >
                        {{ $t('labels.postForSelfSchedule') }}
                      </span>
                    </v-btn>
                  </template>
                  <span class="body-2">
                    {{ $t('labels.postForSelfSchedule') }}
                  </span>
                </v-tooltip>
                <v-tooltip
                  v-if="scheduleIsDraft"
                  bottom
                  nudge-top="10"
                >
                  <template #activator="{ on: tooltipOn, attrs }">
                    <v-btn
                      class="post-draft mr-2"
                      color="primary"
                      :disabled="postingSchedule"
                      :text="$vuetify.breakpoint.lgAndUp"
                      :icon="$vuetify.breakpoint.mdAndDown"
                      :title="$t('labels.postDraft')"
                      v-bind="attrs"
                      v-on="{...tooltipOn, 'click': postSchedule}"
                    >
                      <v-progress-circular
                        v-if="postingSchedule"
                        color="primary lighten-2"
                        indeterminate
                        size="22"
                        width="2"
                      />
                      <v-icon
                        v-else
                        size="20"
                      >
                        fas fa-paper-plane
                      </v-icon>
                      <span
                        v-if="$vuetify.breakpoint.lgAndUp"
                        class="ml-2"
                      >
                        {{ $t('labels.post') }}
                      </span>
                    </v-btn>
                  </template>
                  <span class="body-2">
                    {{ $t('labels.post') }}
                  </span>
                </v-tooltip>
                <v-tooltip
                  v-if="scheduleIsReadyToPublish"
                  bottom
                  nudge-top="10"
                >
                  <template #activator="{ on: tooltipOn, attrs }">
                    <v-btn
                      class="publish-schedule"
                      color="primary"
                      :disabled="publishingSchedule"
                      :text="$vuetify.breakpoint.lgAndUp"
                      :icon="$vuetify.breakpoint.mdAndDown"
                      :title="$t('labels.publishSchedule')"
                      v-bind="attrs"
                      v-on="{...tooltipOn, 'click': publishSchedule}"
                    >
                      <v-progress-circular
                        v-if="publishingSchedule"
                        color="primary lighten-2"
                        indeterminate
                        size="22"
                        width="2"
                      />
                      <v-icon
                        v-else
                        size="20"
                      >
                        fas fa-paper-plane
                      </v-icon>
                      <span
                        v-if="$vuetify.breakpoint.lgAndUp"
                        class="ml-2"
                      >
                        {{ $t('labels.publish') }}
                      </span>
                    </v-btn>
                  </template>
                  <span class="body-2">
                    {{ $t('labels.information') }}
                  </span>
                </v-tooltip>
                <template v-if="needsReview">
                  <v-tooltip
                    bottom
                    nudge-top="10"
                  >
                    <template #activator="{ on: tooltipOn, attrs }">
                      <v-btn
                        class="reject-schedule mr-2"
                        color="error"
                        :disabled="approvingSchedule || rejectingSchedule"
                        :text="$vuetify.breakpoint.lgAndUp"
                        :icon="$vuetify.breakpoint.mdAndDown"
                        :title="$t('labels.reject')"
                        v-bind="attrs"
                        v-on="{...tooltipOn, 'click': rejectSchedule}"
                      >
                        <v-progress-circular
                          v-if="rejectingSchedule"
                          color="primary lighten-2"
                          indeterminate
                          size="22"
                          width="2"
                        />
                        <v-icon
                          v-else
                          size="20"
                        >
                          fas fa-times-circle
                        </v-icon>
                        <span
                          v-if="$vuetify.breakpoint.lgAndUp"
                          class="ml-2"
                        >
                          {{ $t('labels.reject') }}
                        </span>
                      </v-btn>
                    </template>
                    <span class="body-2">
                      {{ $t('labels.reject') }}
                    </span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    nudge-top="10"
                  >
                    <template #activator="{ on: tooltipOn, attrs }">
                      <v-btn
                        class="approve-schedule"
                        color="success"
                        :disabled="approvingSchedule || rejectingSchedule"
                        :text="$vuetify.breakpoint.lgAndUp"
                        :icon="$vuetify.breakpoint.mdAndDown"
                        :title="$t('labels.approve')"
                        v-bind="attrs"
                        v-on="{...tooltipOn, 'click': approveSchedule}"
                      >
                        <v-progress-circular
                          v-if="approvingSchedule"
                          color="primary lighten-2"
                          indeterminate
                          size="22"
                          width="2"
                        />
                        <v-icon
                          v-else
                          size="20"
                        >
                          fas fa-check-circle
                        </v-icon>
                        <span
                          v-if="$vuetify.breakpoint.lgAndUp"
                          class="ml-2"
                        >
                          {{ $t('labels.approve') }}
                        </span>
                      </v-btn>
                    </template>
                    <span class="body-2">
                      {{ $t('labels.approve') }}
                    </span>
                  </v-tooltip>
                </template>
              </v-col>
              <v-spacer />
              <v-col
                align-self="center"
                cols="auto"
              >
                <v-row
                  v-if="scheduleStateIndicator.stateDescKey"
                  align="center"
                  class="ml-1"
                >
                  <v-chip
                    :class="['font-weight-medium ml-5 white--text', scheduleStateIndicator.stateLabelCssClass]"
                    small
                  >
                    {{ $t(scheduleStateIndicator.stateLabelKey) }}
                  </v-chip>
                  <span
                    v-if="$vuetify.breakpoint.lgAndUp"
                    :class="['caption font-weight-medium ml-3', scheduleStateIndicator.stateDescCssClass]"
                  >
                    {{ $t(scheduleStateIndicator.stateDescKey, { date: scheduleStateIndicator.date }) }}
                  </span>
                </v-row>
              </v-col>
              <v-spacer />
              <template v-if="!$vuetify.breakpoint.smAndDown">
                <v-col
                  align-self="center"
                  class="pr-0"
                  cols="auto"
                >
                  <v-tooltip
                    bottom
                    nudge-top="10"
                  >
                    <template #activator="{ on: tooltipOn, attrs }">
                      <v-btn
                        class="mx-1"
                        icon
                        v-bind="attrs"
                        v-on="{...tooltipOn, 'click': toggleHelpPanel}"
                      >
                        <v-icon color="primary">
                          fal fa-info-circle
                        </v-icon>
                      </v-btn>
                    </template>
                    <span class="body-2">
                      {{ $t('labels.information') }}
                    </span>
                  </v-tooltip>
                </v-col>
              </template>
            </v-row>
          </template>
        </portal>
        <v-row
          justify="end"
          no-gutters
        >
          <v-col cols="12">
            <v-tabs
              class="job-types"
              mobile-breakpoint="768"
              show-arrows
              slider-color="accent"
              slider-size="3"
              :value="selectedJobTypeTab"
            >
              <v-tab
                v-for="(jobTypeGroup, index) in groupedJobTypes"
                :key="index"
                @click.native.prevent.stop.capture="onJobTypeChange(index)"
              >
                <template
                  v-for="(jobType, jobIndex) in jobTypeGroup"
                >
                  <span
                    v-if="jobIndex > 0"
                    :key="`${jobType.id}-plus`"
                    class="ml-2"
                  >
                    +
                  </span>
                  <span
                    :key="`${jobType.id}-name`"
                    :class="jobIndex > 0 ? 'ml-2' : ''"
                  >
                    {{ jobType.name }}
                  </span>
                  <template v-if="index > 0">
                    <v-icon
                      v-if="hasValidationErrors(jobType.id)"
                      :key="`${jobType.id}-validation`"
                      class="error--text ml-1 mr-1"
                      x-small
                    >
                      fas fa-exclamation-circle
                    </v-icon>
                  </template>
                  <template v-else>
                    <v-icon
                      v-if="hasValidationErrors()"
                      :key="`${jobType.id}-validation`"
                      class="error--text ml-1 mr-1"
                      x-small
                    >
                      fas fa-exclamation-circle
                    </v-icon>
                  </template>
                </template>
              </v-tab>
            </v-tabs>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row
          class="grid-action-bar"
          justify="center"
          justify-md="end"
        >
          <v-col cols="auto">
            <v-menu
              v-model="showSchedulePicker"
              min-width="250px"
              :close-on-content-click="false"
              offset-y
            >
              <template v-slot:activator="{ on, value }">
                <v-btn
                  class="title font-weight-regular text-capitalize schedule-dropdown px-2"
                  color="primary"
                  elevation="0"
                  outlined
                  width="250px"
                  v-on="on"
                >
                  <span class="body-2 font-weight-medium">
                    {{ selectedScheduleText }}
                  </span>
                  <v-icon
                    size="20"
                  >
                    {{ value ? 'fas fa-caret-up' : 'fas fa-caret-down' }}
                  </v-icon>
                </v-btn>
              </template>
              <ScheduleSelection
                :department="department"
                :selected-schedule-id="selectedScheduleId"
                @select="setSelectedSchedule"
                @close="showSchedulePicker = false"
              />
            </v-menu>
          </v-col>
          <v-spacer />
          <v-col
            v-if="showOpenShiftToggle"
            class="px-0"
            cols="auto"
          >
            <div
              :class="['actions', $vuetify.breakpoint.smAndDown ? '' : 'float-right']"
            >
              <v-tooltip
                top
              >
                <template #activator="{ on, attrs }">
                  <v-badge
                    avatar
                    class="badge-panel count"
                    color="error"
                    :content="openShiftCount"
                    overlap
                    :value="openShiftCount > 0"
                  >
                    <v-btn
                      :class="actionStyles.viewOpenShift.button.classes"
                      icon
                      value="viewOpenShift"
                      v-bind="attrs"
                      v-on="on"
                      @click="setOpenedPanelName('viewOpenShift')"
                    >
                      <v-icon
                        :class="actionStyles.viewOpenShift.icon.classes"
                        size="16"
                      >
                        fal fa-list
                      </v-icon>
                    </v-btn>
                  </v-badge>
                </template>
                <span class="body-2">
                  {{ $t('labels.viewOpenShift') }}
                </span>
              </v-tooltip>
            </div>
          </v-col>
          <v-col
            class="px-0"
            cols="auto"
          >
            <v-tooltip
              top
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  :class="actionStyles.filters.button.classes"
                  icon
                  value="filters"
                  v-bind="attrs"
                  v-on="on"
                  @click="setOpenedPanelName('filters')"
                >
                  <v-icon
                    v-if="hasFilters && openedPanelName !== 'filters'"
                    :class="actionStyles.filters.icon.classes"
                    size="16"
                  >
                    fas fa-filter
                  </v-icon>
                  <v-icon
                    v-else
                    :class="actionStyles.filters.icon.classes"
                    size="16"
                  >
                    fal fa-filter
                  </v-icon>
                </v-btn>
              </template>
              <span class="body-2">
                {{ $t('labels.filter') }}
              </span>
            </v-tooltip>
          </v-col>
          <v-col
            class="px-0"
            cols="auto"
          >
            <v-tooltip
              top
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  :class="actionStyles.download.button.classes"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="download.dialogOpened = true"
                >
                  <v-icon
                    :class="actionStyles.download.icon.classes"
                    size="16"
                  >
                    fal fa-download
                  </v-icon>
                </v-btn>
              </template>
              <span class="body-2">
                {{ $t('labels.download') }}
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
        <StaffSearch
          v-model.trim="staffFilter"
          :append-icon="staffFilter ? '' : 'fal fa-search'"
          target-class="search-staff py-3 ml-4"
          :clearable="!!staffFilter"
          dense
          :disabled="hasChanges"
          hide-details
          nudge-right="100"
          solo
          :target-style="searchStaffStyle"
        />
        <div
          ref="gridParent"
          class="grid-parent"
          :style="gridStyle"
        />
        <svg
          :style="gridOverlayStyle"
        >
          <polygon
            :points="gridOverlayPath"
            style="fill-opacity:0.05;stroke:transparent;stroke-width:0;"
          />
        </svg>
      </v-container>
      <template v-if="$vuetify.breakpoint.smAndDown">
        <v-btn
          :class="['elevation-0 schedule-summary-row-toggle', { active: mobileSummaryRow }]"
          fixed
          :ripple="false"
          small
          :style="{bottom: mobileSummaryRow ? `${footerHeight + 54}px` : '54px'}"
          @click="mobileSummaryRow = !mobileSummaryRow"
        >
          <v-icon
            color="error"
            x-small
          >
            {{ mobileSummaryRow ? 'far fa-chevron-down' : 'far fa-chevron-up' }}
          </v-icon>
        </v-btn>
        <v-footer
          class="elevation-0 schedule-summary-row pb-0"
          fixed
          :style="{bottom: mobileSummaryRow ? '56px' : `${56 - footerHeight}px`}"
        >
          <v-btn
            v-for="validator in validators"
            :key="`tab${getValidatorName(validator)}`"
            class="mx-2 mb-2 caption font-weight-medium text-capitalize"
            depressed
            @click="showErrorsOverview(validator)"
          >
            <v-chip
              class="mr-2 px-2 white--text"
              :color="getValidatorErrorCount(validator) > 0 ? 'error' : 'primary lighten-2'"
              label
              x-small
              @click.stop="showErrorsOverview(validator)"
            >
              {{ getValidatorErrorCount(validator) }}
            </v-chip>
            {{ $t(`labels.${getValidatorName(validator)}`) }}
            <v-icon
              class="pl-2 grey--text text--darken-1"
              :style="{ visibility: hasTabFilters ? 'visible' : 'hidden' }"
              x-small
            >
              fas fa-filter
            </v-icon>
          </v-btn>
        </v-footer>
      </template>
      <v-footer
        v-else
        fixed
        class="schedule-summary-row pr-8 pb-0"
        :style="summaryRowStyle"
      >
        <v-btn
          v-for="validator in validators"
          :key="`tab${getValidatorName(validator)}`"
          class="mx-2 caption font-weight-medium text-capitalize mb-2"
          depressed
          @click="showErrorsOverview(validator)"
        >
          <v-chip
            class="mr-2 px-2 white--text"
            :color="getValidatorErrorCount(validator) > 0 ? 'error' : 'primary lighten-2'"
            label
            x-small
            @click.stop="showErrorsOverview(validator)"
          >
            {{ getValidatorErrorCount(validator) }}
          </v-chip>
          {{ $t(`labels.${getValidatorName(validator)}`) }}
          <v-icon
            class="pl-2 grey--text text--darken-1"
            :style="{ visibility: hasTabFilters ? 'visible' : 'hidden' }"
            x-small
          >
            fas fa-filter
          </v-icon>
        </v-btn>
        <v-spacer />
      </v-footer>
      <v-dialog
        v-model="download.dialogOpened"
        eager
        persistent
        fullscreen
      >
        <v-card
          id="downloadMasterSchedule"
        >
          <v-card-title class="body-2 d-block mb-2">
            <span class="body-2 font-weight-medium">
              {{ $t('labels.downloadMasterSchedule') }}
            </span>
            <span class="body-2 font-weight-medium primary--text">
              {{ selectedScheduleText }}
            </span>
            <v-btn
              class="float-right mt-n1"
              icon
              small
              @click="download.dialogOpened = false"
            >
              <v-icon small>
                fal fa-times
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text
            class="pa-0 ma-0"
            :style="downloadGridContainerStyle"
          >
            <div
              ref="downloadGrid"
              class="download-grid"
              :style="downloadGridStyle"
            />
          </v-card-text>
          <v-footer
            color="white"
            fixed
            height="75px"
          >
            <v-select
              v-model="download.pageSize"
              dense
              hide-details
              :items="[{text: $t('labels.letter'), value: 'LETTER'}, {text: $t('labels.legal'), value: 'LEGAL'}]"
              :label="$t('labels.pageSize')"
              outlined
              style="max-width: 184px"
            />
            <v-spacer />
            <v-btn
              text
              @click="download.dialogOpened = false"
            >
              {{ $t('labels.cancel') }}
            </v-btn>
            <v-btn
              class="start-download"
              color="secondary"
              @click="downloadSchedule"
            >
              <v-progress-circular
                v-if="download.inProgress"
                class="px-12"
                color="white"
                indeterminate
                size="22"
                width="2"
              />
              <span v-else>
                {{ $t('labels.downloadPDF') }}
              </span>
            </v-btn>
          </v-footer>
        </v-card>
      </v-dialog>
      <SidePanel
        :panels="sidePanels"
        @transitionend="redrawGrid(true)"
      />
      <UserDialog
        v-if="nurseDetails"
        :show-hint="false"
        :user="{ ...nurseDetails }"
        @close="hideNurseDetails"
        @saved="updateNurseDetails"
      />
    </template>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfMakeUnicode from 'pdfmake-unicode';
import * as Sentry from '@sentry/vue';
import { mapState } from 'vuex';
import {
  DATE_FORMAT,
  DEFAULT_FONT_FAMILY,
  DEFAULT_FONT_SIZE_TABLE,
  SEARCH_INPUT_DEBOUNCE,
  getUnsavedChangesDialogProps
} from '@/utils';
import { showStatus } from '@/plugins/vue-notification';
import Mousetrap from '@/plugins/mousetrap';
import EventRequest from '@/views/scheduling/requests/EventRequest';
import ShiftRequest from '@/views/scheduling/requests/ShiftRequest';
import SwapRequest from '@/views/scheduling/requests/SwapRequest';
import DailySummary from '@/views/scheduling/panels/DailySummary';
import ActivityDetails from '@/views/scheduling/panels/ActivityDetails';
import NurseDetails from '@/views/scheduling/panels/NurseDetails';
import ShiftDetails from '@/views/scheduling/panels/ShiftDetails';
import EventDetails from '@/views/scheduling/panels/EventDetails';
import ActivitySelection from '@/components/scheduling/ActivitySelection';
import ScheduleSelection from '@/components/scheduling/ScheduleSelection';
import SelectCell from '@/views/scheduling/panels/SelectCell';
import EmptyCell from '@/views/scheduling/panels/EmptyCell';
import Help from '@/views/scheduling/panels/Help';
import RequestsOverview from '@/views/scheduling/panels/RequestsOverview';
import ErrorsOverview from '@/views/scheduling/panels/ErrorsOverview';
import WeeklySummary from '@/views/scheduling/panels/WeeklySummary';
import UserWeeklySummary from '@/views/scheduling/panels/UserWeeklySummary';
import ReviewComments from '@/views/scheduling/panels/ReviewComments';
import Filters from '@/views/scheduling/panels/Filters';
import SidePanel from '@/components/SidePanel';
import DepartmentSelector from '@/components/DepartmentSelector';
import OpenShiftsList from '@/views/scheduling/open_shifts/List';
import CreateOpenShift from '@/views/scheduling/open_shifts/Create';
import OpenShiftDetails from '@/views/scheduling/open_shifts/Details';
import StaffSearch from '@/components/StaffSearch';
import UserDialog from '@/views/admin/users/UserDialog';
import { SCHEDULE_STATES } from '@/views/scheduling/constants';
import { calculateScheduleRangeForDate, getDefaultFilters, isProductiveShift, isWorkingShiftForDisplay, wasShiftModifiedByManagement } from '@/utils/scheduling';
import { CONTENT_TYPES, ERROR_CODES } from '@/services/constants';
import scssVariables from '@/assets/sass/_variables.scss';
import { userMatchesText } from '@/utils/org';

const DeepDiff = require('deep-diff').diff;
const cheetahGrid = require('@nurse-brite/cheetah-grid');

pdfMake.vfs = pdfMakeUnicode.pdfMake.vfs;

export default {
  components: {
    DepartmentSelector,
    ScheduleSelection,
    SidePanel,
    StaffSearch,
    UserDialog
  },

  props: {
    showSkeletonLoader: {
      default: true,
      type: Boolean
    }
  },

  data () {
    const ALL_SHIFT_TYPES = 0;
    const ALL_JOB_TYPES = 0;

    return {
      ALL_JOB_TYPES,
      ALL_SHIFT_TYPES,
      COL_WIDTH_SCHEDULE: 40,
      COL_WIDTH_USER: 200,
      FROZEN_COLUMN_COUNT: 1,
      SIDE_PANEL_WIDTH: 440,
      approvingSchedule: false,
      download: {
        dialogOpened: false,
        grid: null,
        inProgress: false,
        limit: {
          'LEGAL': 18,
          'LETTER': 23
        },
        page: 0,
        pageSize: 'LETTER'
      },
      filters: getDefaultFilters(),
      footerHeight: 55,
      grid: null,
      gridHeight: 500,
      gridTop: 0,
      gridWidth: 500,
      gridState: {
        canScrollLeft: false,
        canScrollRight: false,
        scrollLeft: 0,
        todaysRectangle: null
      },
      gridfilterDataSource: null,
      hasChanges: false,
      helpPanel: [],
      helpPanelData: {
        id: _.uniqueId(),
        component: Help,
        props: {},
        events: {
          close: () => {
            this.toggleHelpPanel();
          }
        }
      },
      innerHeight: 500,
      innerWidth: 500,
      showHelp: false,
      mobileSummaryRow: false,
      mousetrap: new Mousetrap(this.$el),
      nurseDetails: null,
      openedPanelName: undefined,
      openShiftCount: 0,
      openShiftDate: '',
      panels: [], // Opened right side panels
      persistentPanels: [],
      postingSchedule: false,
      postingScheduleForSelfSchedule: false,
      publishingSchedule: false,
      rejectingSchedule: false,
      retrievingSchedule: true, // Flag for paginating through different time period of the schedule.
      savingChanges: false,
      selectedCell: {
        col: null,
        user: null
      },
      selectedJobTypeTab: ALL_JOB_TYPES,
      selectedRequest: null,
      selectedSchedule: null,
      selectedScheduleId: this.$store.state.scheduling.activeScheduleId,
      showPanelOnCellSelect: false,
      showSchedulePicker: false,
      staffFilter: '',
      viewWeeklyBalance: false
    };
  },

  computed: {
    actionStyles () {
      const defaultButtonClasses = ['grey', 'lighten-2', 'mr-2'];
      const defaultIconClasses = ['grey--text', 'text--darken-3'];
      const styles = {
        download: {
          button: {
            classes: defaultButtonClasses.concat(['download'])
          },
          icon: {
            classes: defaultIconClasses
          }
        },
        filters: {
          button: {
            classes: defaultButtonClasses.concat(['filters'])
          },
          icon: {
            classes: defaultIconClasses
          }
        },
        viewOpenShift: {
          button: {
            classes: defaultButtonClasses.concat(['view-open-shift'])
          },
          icon: {
            classes: defaultIconClasses
          }
        }
      };

      if (this.hasFilters) {
        styles.filters = {
          button: {
            classes: ['primary', 'lighten-2', 'mr-2', 'filters']
          },
          icon: {
            classes: ['white--text']
          }
        };
      }

      if (this.openedPanelName && styles[this.openedPanelName]) {
        styles[this.openedPanelName].button.classes = ['primary', 'mr-2', this.openedPanelName];
        styles[this.openedPanelName].icon.classes = ['white--text'];
      }

      return styles;
    },
    allDepartments () {
      return this.$store.state.org.departments.reduce(function (accumulator, currentValue) {
        accumulator[currentValue.id] = currentValue;
        return accumulator;
      }, {});
    },
    dateFormatShort () {
      return this.$store.getters['org/getDateFormatShort']();
    },
    dateFormatLong () {
      return this.$store.getters['org/getDateFormatLong']();
    },
    emptyMessage () {
      let message = '';
      const containerClasses = 'body-1 font-weight-medium grey--text text--darken-3 pa-5';
      if (this.staffFilter) {
        message = `
          <div class="${containerClasses}">
            ${this.$t('descriptions.emptyGridStaffFilter', { staff_filter: _.escape(this.staffFilter) })}
            <hr role="separator" aria-orientation="horizontal" class="my-5 v-divider theme--light">
            <div class="mb-5">
              ${this.$t('descriptions.emptyGridHelpTitle')}
            </div>
            <ul>
              <li>${this.$t('descriptions.emptyGridHelpRemoveFilters')}</li>
              <li>${this.$t('descriptions.emptyGridHelpShiftTypes')}</li>
              <li>${this.$t('descriptions.emptyGridHelpShorterWords')}</li>
              <li>${this.$t('descriptions.emptyGridHelpSpelling')}</li>
            </ul>
          </div>
        `;
      } else {
        const jobTypes = this.getSelectedJobTypes();
        if (this.selectedJobTypeTab !== this.ALL_JOB_TYPES && jobTypes) {
          const descriptions = jobTypes.map((jt) => jt.description.toLowerCase()).join('/');
          message = `
            <div class="${containerClasses}">
              ${this.$t('descriptions.emptyGridSpecificJobType', { job_type: _.escape(descriptions) })}
            </div>
          `;
        } else {
          message = `
            <div class="${containerClasses}">
              ${this.$t('descriptions.emptyGridAllJobTypes')}
            </div>
          `;
        }
      }

      return message;
    },
    eventTypes () {
      return this.$store.state.org.eventTypes.reduce(function (eventTypes, eventType) {
        eventTypes[eventType.id] = eventType;
        return eventTypes;
      }, {});
    },
    gridLanguage () {
      return {
        'clear': this.$t('labels.clear'),
        'daysOfWeek': [
          this.$t('dates.dayOfWeek.0'),
          this.$t('dates.dayOfWeek.1'),
          this.$t('dates.dayOfWeek.2'),
          this.$t('dates.dayOfWeek.3'),
          this.$t('dates.dayOfWeek.4'),
          this.$t('dates.dayOfWeek.5'),
          this.$t('dates.dayOfWeek.6')
        ],
        'months': [
          this.$t('dates.month.0'),
          this.$t('dates.month.1'),
          this.$t('dates.month.2'),
          this.$t('dates.month.3'),
          this.$t('dates.month.4'),
          this.$t('dates.month.5'),
          this.$t('dates.month.6'),
          this.$t('dates.month.7'),
          this.$t('dates.month.8'),
          this.$t('dates.month.9'),
          this.$t('dates.month.10'),
          this.$t('dates.month.11')
        ],
        'disclaimerHint': this.$t('descriptions.disclaimer'),
        'visibilityHint': this.$t('descriptions.commentVisibilitySchedulers'),
        'notes': this.$t('labels.notes'),
        'notesPlaceholder': `${this.$t('labels.addNotesPlaceholder')} ${this.$t('descriptions.userNotesPlaceholder')}`,
        'save': this.$t('labels.save'),
        'viewStaffDetails': this.$t('labels.viewStaffDetails'),
        'week': this.$t('labels.week')
      };
    },
    groupedJobTypes () {
      const groupedJobTypes = [];
      const jobTypes = this.jobTypes;
      let group = [jobTypes[0]];
      for (let i = 1, count = jobTypes.length; i < count; i++) {
        if (group[group.length - 1].groupRight) {
          group.push(jobTypes[i]);
        } else {
          groupedJobTypes.push([...group]);
          group = [jobTypes[i]];
        }
      }
      groupedJobTypes.push(group);

      return groupedJobTypes;
    },
    hasFilters () {
      const diff = DeepDiff(getDefaultFilters(), this.filters) || [];
      return diff.length > 0;
    },
    hasActivityFilters () {
      const activityFilters = _.cloneDeep(this.filters);
      delete activityFilters.user;
      const defaultFilters = getDefaultFilters();
      delete defaultFilters.user;
      const diff = DeepDiff(defaultFilters, activityFilters) || [];
      return diff.length > 0;
    },
    hasTabFilters () {
      return this.selectedJobTypeTab !== this.ALL_JOB_TYPES;
    },
    jobTypes () {
      let jobTypes = [
        {
          description: 'All Job Types',
          groupRight: false,
          id: 0,
          name: 'All',
          associatedShiftTypes: [],
          associatedJobTypes: []
        }
      ];
      if (this.schedule) {
        const scheduleJobTypes = this.$store.getters['scheduling/getJobTypes'](this.selectedScheduleId);

        for (let i = 0, count = scheduleJobTypes.length; i < count; i++) {
          const jobInfo = scheduleJobTypes[i];
          jobTypes.push({
            ...jobInfo
          });
          jobTypes[0].associatedShiftTypes.push(...jobInfo.associatedShiftTypes);
          jobTypes[0].associatedJobTypes.push(...jobInfo.associatedJobTypes);
        }

        jobTypes[0].associatedShiftTypes = _.uniq(jobTypes[0].associatedShiftTypes);
        jobTypes[0].associatedJobTypes = _.uniq(jobTypes[0].associatedJobTypes);
      }

      return jobTypes;
    },
    jobTypesById () {
      return this.$store.state.org.jobTypes.reduce(
        (obj, jobType) => {
          obj[jobType.id] = jobType;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    draftStartDate () {
      const hospitalSettings = this.$store.state.org.settings.scheduling;
      const nurseSelfScheduleLatent = hospitalSettings['nurseSelfSchedule']['latent'];
      const nurseSelfSchedulePeriod = hospitalSettings['nurseSelfSchedule']['period'];
      const schedulePeriod = hospitalSettings['period'];
      const draftStartDate = moment(this.schedule.startOn).subtract(schedulePeriod - nurseSelfScheduleLatent - nurseSelfSchedulePeriod, 'd');
      return draftStartDate.format(this.dateFormatLong);
    },
    department () {
      const settings = this.$store.state.org.settings;
      const department = this.$store.getters['org/getActiveDepartment']() || {};
      const days = _.get(settings, ['scheduling', 'period'], 42);
      return {
        numOfWeeksPerSchedule: Math.ceil(days / 7),
        ...department
      };
    },
    scheduleSymbols () {
      return this.$store.state.org.settings.scheduling.symbols;
    },
    schedulePeriodSettings () {
      return this.$store.state.org.settings.scheduling;
    },
    searchStaffStyle () {
      return {
        width: `${this.COL_WIDTH_USER * 0.85}px`
      };
    },
    shiftTypes () {
      return this.$store.state.org.shiftTypes.reduce(function (shiftTypes, shiftType) {
        shiftTypes[shiftType.id] = { ...shiftType };
        // Filter out empty properties from the styles object that have empty values to allow code to easily
        // override styles for validation errors.
        shiftTypes[shiftType.id].styles = _.pickBy(shiftTypes[shiftType.id].styles, _.identity);
        return shiftTypes;
      }, {});
    },
    shiftTypesForSelectedJobType () {
      const jobTypes = this.getSelectedJobTypes();
      const shiftTypes = [];
      for (let jt of jobTypes) {
        shiftTypes.push(...jt.associatedShiftTypes);
      }

      return _.map(_.uniq(shiftTypes), (id) => {
        return this.shiftTypes[id];
      });
    },
    shiftTypesForSelectedJobTypeWithoutOnCall () {
      return _.filter(this.shiftTypesForSelectedJobType, (shiftType) => !shiftType.onCall);
    },
    shortcutActions () {
      const actions = {};
      let shortcut;
      const action = (shortcut, userId, date) => {
        const shiftId = shortcut.id;
        if (this.getShiftTypeIdsForUser(this.$store.state.org.employees[userId]).includes(shiftId)) {
          this.dispatch('scheduling/createShifts', [{
            assigneeId: userId,
            departmentId: this.department.id,
            startTime: this.shiftTypes[shiftId].startTime,
            endTime: this.shiftTypes[shiftId].endTime,
            obligatory: shortcut.obligatory,
            onCall: this.shiftTypes[shiftId].onCall,
            scheduleId: this.selectedScheduleId,
            typeId: shiftId,
            date: date.format('YYYY-MM-DD')
          }]).then((shifts) => {
            this.$store.commit('scheduling/add_shift', {
              ...shifts[0],
              date,
              type: 'shift'
            });
            showStatus({
              text: this.$t('descriptions.shiftSaveSuccess')
            });
            this.$nextTick(() => {
              this.redrawGrid();
              this.refreshPanels();
            });
          }).catch(error => {
            const data = {
              error: _.get(error, 'response.data')
            };

            showStatus({
              text: this.$t('descriptions.shiftSaveFail'),
              type: 'error',
              data
            });
          });
        }
      };
      for (let shiftId in this.shiftTypes) {
        shortcut = _.get(this.shiftTypes, [shiftId, 'styles', 'web', 'keyboardShortcut'], '');
        if (shortcut) {
          actions[shortcut] = {
            id: this.shiftTypes[shiftId].id,
            obligatory: false,
            action
          };
          actions[`shift+${shortcut}`] = {
            id: this.shiftTypes[shiftId].id,
            obligatory: true,
            action
          };
        }
      }
      return actions;
    },
    gridOverlayStyle () {
      if (this.scheduleIsReadOnly) {
        return {
          height: this.gridStyle.height,
          'pointer-events': 'none',
          position: 'absolute',
          top: `${this.gridTop - 48}px`,
          width: `${this.gridWidth}px`
        };
      } else if (this.todaysColumnIndex >= 0 && this.gridState.todaysRectangle) {
        const width = this.gridState.todaysRectangle.left - this.gridState.scrollLeft;
        return {
          height: this.gridStyle.height,
          'pointer-events': 'none',
          position: 'absolute',
          top: `${this.gridTop - 48}px`,
          width: `${width}px`
        };
      }

      return {
        display: 'none'
      };
    },
    gridOverlayPath () {
      const USER_COLUMN_WIDTH = 200;
      let path = [];
      // Subtract 10 from width and height for parent padding
      if (this.scheduleIsReadOnly) {
        const WIDTH = this.gridWidth - 10;
        const HEIGHT = parseInt(this.gridStyle.height) - 10;
        path = [
          `${USER_COLUMN_WIDTH},0`,
          `${WIDTH},0`,
          `${WIDTH},${HEIGHT}`,
          `${USER_COLUMN_WIDTH},${HEIGHT}`
        ];
      } else if (this.todaysColumnIndex >= 0 && this.gridState.todaysRectangle) {
        const WIDTH = Math.min(this.gridState.todaysRectangle.left - this.gridState.scrollLeft, this.gridWidth - 10);
        const HEIGHT = parseInt(this.gridStyle.height) - 10;
        path = [
          `${USER_COLUMN_WIDTH},0`,
          `${WIDTH},0`,
          `${WIDTH},${HEIGHT}`,
          `${USER_COLUMN_WIDTH},${HEIGHT}`
        ];
      }

      return path.join(' ');
    },
    downloadGridContainerStyle () {
      return {
        height: `${this.innerHeight - 130}px`,
        width: `${this.innerWidth}px`,
        overflow: 'scroll'
      };
    },
    downloadGridStyle () {
      let width = 16;
      for (let h of this.printHeaders) {
        width += h.width;
      }
      let height = (this.download.limit[this.download.pageSize] * 60) + (this.printFooters.length * 25) + 60 + 16;
      return {
        height: `${height * 5}px`,
        width: `${width}px`,
        opacity: 1.0
      };
    },
    gridStyle () {
      // The grid has two parent containers and each one has 12px padding and here
      // we add an extra 6px buffer.
      let padding = 0;
      if (this.$vuetify.breakpoint.smAndDown) {
        // smAndDown triggers the navigation bar to the bottom of the screen therefore
        // the offset here is the height of that navigation bar.
        padding += 60;
        if (this.mobileSummaryRow) {
          padding += 48;
        }
      } else {
        padding += 50;
      }

      return {
        height: `${this.gridHeight - padding - (this.footerHeight - 55)}px`,
        opacity: 1.0
      };
    },
    gridInitialized () {
      return !!this.grid;
    },
    gridMaxWidth () {
      const MARGIN = 40; // Compensate left and right margin of the grid.
      const widthForScheduleCols = this.department.numOfWeeksPerSchedule * 7 * this.COL_WIDTH_SCHEDULE; // Hospital opens 7 days a week.
      const maxWidthForGrid = this.COL_WIDTH_USER + widthForScheduleCols + MARGIN;

      return {
        maxWidth: maxWidthForGrid + 'px'
      };
    },
    needsReview () {
      return !this.scheduleIsReadOnly && _.indexOf([SCHEDULE_STATES.PENDING_POST_APPROVAL, SCHEDULE_STATES.PENDING_PUBLISH_APPROVAL], this.state) >= 0;
    },
    scheduleAllowsShiftDeletion () {
      return !this.scheduleIsReadOnly;
    },
    scheduleIsDraft () {
      return this.state === SCHEDULE_STATES.DRAFT;
    },
    scheduleIsInitial () {
      return this.state === SCHEDULE_STATES.INITIAL;
    },
    scheduleIsReadOnly () {
      if (!this.$can('edit', 'masterSchedule')) {
        return true;
      }
      const stateInfo = _.find(_.get(this.$store.state.org, 'settings.scheduling.states', []), (s) => s.state === this.state);
      if (stateInfo) {
        return !stateInfo.allowEditing;
      }
      return !!this.state;
    },
    scheduleIsReadyToPublish () {
      return !this.scheduleIsReadOnly && moment().isSameOrAfter(this.schedulePublishDueDate, 'day') && this.state === SCHEDULE_STATES.UNDER_NURSE_REVIEW;
    },
    schedulePeriods () {
      const schedulingPeriods = this.department.schedulingPeriods;
      const periods = [];
      if (schedulingPeriods) {
        for (let i = 0, len = schedulingPeriods.length; i < len; i++) {
          if (!schedulingPeriods[i].id) {
            continue;
          }
          const startFrom = moment(schedulingPeriods[i].startOn);
          const endBy = moment(schedulingPeriods[i].endOn);
          let text = '';
          if (endBy.isSame(startFrom, 'year')) {
            text = startFrom.format(this.dateFormatShort) + ' - ' + endBy.format(this.dateFormatLong);
          } else {
            // Display year info for both start and end date if the end date is in different year.
            text = startFrom.format(this.dateFormatLong) + ' - ' + endBy.format(this.dateFormatLong);
          }
          periods.push({
            text,
            value: schedulingPeriods[i].id,
            ...schedulingPeriods[i]
          });
        }
      }
      return periods;
    },
    schedulePublishDueDate () {
      let date = moment(this.schedule.startOn);
      date.subtract(this.schedulePeriodSettings.postLeadTime, 'd');
      date.add(this.schedulePeriodSettings.nurseReviewPeriod, 'd');
      return date;
    },
    showDailyScheduleRedirect () {
      return false;
    },
    showOpenShiftToggle () {
      return this.$can('view', 'openShift') && [SCHEDULE_STATES.DRAFT, SCHEDULE_STATES.UNDER_NURSE_REVIEW].includes(this.state);
    },
    scheduleStateIndicator () {
      return this.getScheduleStateIndicator({
        state: this.state,
        startOn: this.schedule.startOn,
        endOn: this.schedule.endOn
      }, this.$vuetify.breakpoint.lgAndDown);
    },
    scheduleStates () {
      return _.get(this.$store.state.org, 'settings.scheduling.states', []).map((item) => item.state);
    },
    hasSelectedShiftTypes () {
      return this.selectedShiftTypes.length > 0;
    },
    selectedShiftTypes () {
      return _.get(this.filters, 'shift.types', []);
    },
    sidePanels () {
      const helpPanel = [{
        id: _.uniqueId(),
        component: Help,
        props: {},
        events: {
          close: () => {
            this.toggleHelpPanel();
          }
        }
      }];

      if (this.showHelp) {
        return helpPanel;
      } else {
        return [...this.persistentPanels, ...this.panels];
      }
    },
    schedule () {
      return this.$store.state.scheduling.schedules[this.selectedScheduleId];
    },
    shiftFlags () {
      return this.$store.state.org.flags.reduce((flags, value) => {
        flags[value.id] = value;
        return flags;
      }, {});
    },
    state () {
      return this.schedule ? this.schedule.state : '';
    },
    summaryRowStyle () {
      return {
        'left': this.$vuetify.breakpoint.smAndDown ? '0px' : '100px'
      };
    },
    headers () {
      const headers = _.cloneDeep(_.get(this.$store.state.scheduling.grids[this.selectedScheduleId], ['headers'], []));
      const filteredHeaders = [];
      for (let i = 0, len = headers.length; i < len; i++) {
        if (headers[i].type === 'user') {
          filteredHeaders.push({
            columnType: 'user',
            headerType: 'user',
            maxWidth: 200,
            minWidth: 200,
            width: 200,
            renderNameAsLink: true,
            showAvatar: true,
            showSettings: true,
            status: (user) => {
              let status = user.jobTypeName ? user.jobTypeName : '';
              status += user.jobStatusShortCode ? ` ${user.jobStatusShortCode}` : '';
              if (user.departmentId !== this.department.id) {
                status += ` │ ${user.departmentName}`;
              }
              return status;
            },
            ...headers[i]
          });
        } else if (headers[i].type === 'schedule') {
          filteredHeaders.push({
            columnType: 'schedule',
            headerType: 'schedule',
            maxWidth: 40,
            minWidth: 40,
            width: 40,
            ...headers[i]
          });
        } else if (headers[i].type === 'week') {
          filteredHeaders.push({
            columnType: 'week',
            headerType: 'week',
            maxWidth: 40,
            minWidth: 40,
            style: {
              bgColor: '#F5F5F5'
            },
            width: 40,
            ...headers[i]
          });
        }
      }
      return filteredHeaders;
    },
    printHeaders () {
      const headers = _.cloneDeep(this.headers);
      const idx = _.findIndex(headers, (h) => h.headerType === 'user');
      if (idx >= 0) {
        headers[idx].maxWidth = 150;
        headers[idx].minWidth = 150;
        headers[idx].width = 150;
        headers[idx].showAvatar = false;
        headers[idx].showSettings = false;
        headers[idx].renderNameAsLink = false;
      }
      return headers;
    },
    todaysColumnIndex () {
      return _.findIndex(
        this.headers,
        (h) => {
          return h.columnType === 'schedule' && moment(h.caption).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD');
        }
      );
    },
    footers () {
      if (this.selectedJobTypeTab === this.ALL_JOB_TYPES) {
        return [];
      } else {
        const imbalance = this.$store.getters['scheduling/getValidator'](this.selectedScheduleId, 'ImbalanceValidator');
        if (!imbalance) {
          return [];
        }
        const style = {
          bgColor: '#F5F5F5',
          color: '#616161',
          errorBgColor: '#FDE3E3',
          errorColor: '#E74C3C'
        };
        const hasError = (jobId, shiftId) => {
          return function (count) {
            return !imbalance.validShiftCount(jobId, shiftId, count);
          };
        };
        const data = imbalance.state.data;
        const footers = [];

        const jobTypes = this.getSelectedJobTypes();
        if (!this.hasSelectedShiftTypes) {
          for (let jt of jobTypes) {
            for (let i = 0, len = jt.associatedShiftTypes.length; i < len; i++) {
              const shiftId = jt.associatedShiftTypes[i];
              if (_.has(data, [jt.id, shiftId, 'dates'])) {
                footers.push(
                  {
                    type: 'balance',
                    label: `${jt.name} (${this.shiftTypesById[shiftId].name})`,
                    style,
                    hasError: hasError(jt.id, shiftId),
                    data: data[jt.id][shiftId].dates
                  }
                );
              }
            }
          }
        } else {
          for (let jt of jobTypes) {
            for (let shiftTypeId of this.selectedShiftTypes) {
              if (jt.associatedShiftTypes.includes(shiftTypeId)) {
                if (_.has(data, [jt.id, shiftTypeId, 'dates'])) {
                  footers.push(
                    {
                      type: 'balance',
                      label: `${jt.name} (${this.shiftTypes[shiftTypeId].name})`,
                      hasError: hasError(jt.id, shiftTypeId),
                      style,
                      data: data[jt.id][shiftTypeId].dates
                    }
                  );
                }
              }
            }
          }
        }
        return footers;
      }
    },
    printFooters () {
      const pageCount = Math.ceil(this.printRecords.length / this.download.limit[this.download.pageSize]);
      if (this.download.inProgress && this.download.page < pageCount - 1) {
        return [];
      } else {
        return this.footers;
      }
    },
    allRecords () {
      return _.get(this.$store.state.scheduling.grids[this.selectedScheduleId], ['records'], []);
    },
    records () {
      const records = _.get(this.$store.state.scheduling.grids[this.selectedScheduleId], ['records'], []);
      const filteredRecords = [];
      const shiftFilterEnabled = _.get(this.filters, 'shift.enabled', true);
      const eventFilterEnabled = _.get(this.filters, 'event.enabled', true);
      let flagIds = this.$store.state.org.flags.map((f) => f.id);
      if (_.get(this.filters, 'shift.flags', []).length > 0) {
        flagIds = this.filters.shift.flags;
      }
      const eventMatches = (a) => {
        if (this.filters.event) {
          return (this.filters.event.types.length === 0 || this.filters.event.types.includes(a.typeId));
        }
        return true;
      };
      const shiftMatches = (a) => {
        if (this.filters.shift) {
          let matches = (this.filters.shift.types.length === 0 || this.filters.shift.types.includes(a.typeId));
          if (matches) {
            switch (this.filters.shift.mode) {
              case 'available':
                matches &= a.available;
                break;
              case 'canceled':
                matches &= a.canceled;
                break;
            }
          }
          if (matches) {
            const intersection = _.intersection(flagIds, a.flags).length;
            switch (this.filters.shift.flagsOp) {
              case 'or':
                if (this.filters.shift.flags.length === 0) {
                  matches &= (intersection > 0 || a.flags.length === 0);
                } else {
                  matches &= intersection > 0;
                }
                break;
              case 'and':
                matches &= intersection === flagIds.length;
                break;
            }
          }
          return matches;
        }
        return true;
      };
      for (let r of records) {
        const row = {
          user: r.user
        };
        let activityCount = 0;
        for (let d in r) {
          if (d !== 'user') {
            row[d] = {
              activities: [],
              request: r[d].request,
              exclude: r[d].exclude || false
            };

            for (let a of r[d].activities) {
              switch (a.type) {
                case 'shift':
                  if (shiftFilterEnabled && shiftMatches(a)) {
                    row[d].activities.push(a);
                    activityCount++;
                  }
                  break;
                case 'event':
                  if (eventFilterEnabled && eventMatches(a)) {
                    row[d].activities.push(a);
                    activityCount++;
                  }
                  break;
              }
            }
          }
        }
        if (!this.hasActivityFilters || activityCount > 0) {
          filteredRecords.push(row);
        }
      }
      return filteredRecords;
    },
    printRecords () {
      const filters = this.getGridFilter();
      let records = this.records;
      if (filters) {
        records = _.filter(this.records, (r) => filters(r));
      }
      return records;
    },
    paginatedPrintRecords () {
      let records = this.printRecords;
      if (this.download.inProgress) {
        const start = this.download.page * this.download.limit[this.download.pageSize];
        const end = start + this.download.limit[this.download.pageSize];
        records = records.slice(start, end);
      }
      return records;
    },
    shiftTypesById () {
      return this.$store.state.org.shiftTypes.reduce(
        (obj, shiftType) => {
          obj[shiftType.id] = shiftType;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    jobStatusById () {
      return this.$store.state.org.jobStatus.reduce(
        (obj, jobStatus) => {
          obj[jobStatus.id] = jobStatus;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    allUserRecordMap () {
      return _.get(this.$store.state.scheduling.grids[this.selectedScheduleId], ['userRecordMap'], {});
    },
    userRecordMap () {
      const userRecordMap = {};
      for (let i = 0, count = this.records.length; i < count; i++) {
        userRecordMap[this.records[i].user.userId] = i;
      }
      return userRecordMap;
    },
    totalErrorCount () {
      return this.$store.getters['scheduling/getErrorCount'](this.selectedScheduleId);
    },
    selectedScheduleText () {
      const startFrom = moment(this.selectedSchedule.startOn);
      const endBy = moment(this.selectedSchedule.endOn);
      let text = '';
      if (endBy.isSame(startFrom, 'year')) {
        text = startFrom.format(this.dateFormatShort) + ' - ' + endBy.format(this.dateFormatLong);
      } else {
        // Display year info for both start and end date if the end date is in different year.
        text = startFrom.format(this.dateFormatLong) + ' - ' + endBy.format(this.dateFormatLong);
      }
      return text;
    },
    validators () {
      const validators = _.values(this.$store.getters['scheduling/getValidator'](this.selectedScheduleId));
      return _.sortBy(validators, [function (v) { return v.constructor.priority; }]);
    },
    ...mapState(['sidePanelOpened'])
  },
  watch: {
    department () {
      this.redraw();
    },
    filters: {
      handler () {
        if (this.grid) {
          this.filterGrid(true);
        }
      },
      deep: true
    },
    gridLanguage () {
      if (this.grid) {
        this.grid.language = this.gridLanguage;
        this.updateGridEmptyMessage();
      }
    },
    'download.dialogOpened' () {
      if (this.download.dialogOpened) {
        this.renderDownloadGrid();
      } else {
        if (this.download.grid) {
          this.download.grid.dispose();
        }
        this.download.grid = null;
        this.download.inProgress = false;
        this.download.page = 0;
      }
    },
    'download.pageSize' () {
      this.renderDownloadGrid();
    },
    'panels.length' (newLength, prevLength) {
      if (newLength === 0) {
        this.showPanelOnCellSelect = false;
        this.$store.commit('scheduling/update_panel', { panel: 'dailySummary', prop: 'tab', value: 'summary' });
      }
    },
    'records' () {
      if (this.grid) {
        const self = this;
        this.gridfilterDataSource = new cheetahGrid.data.FilterDataSource({
          get (index) {
            return self.records[index] || {};
          },
          length: self.records.length
        });
        this.gridfilterDataSource.filter = this.getGridFilter();
        this.grid.dataSource = this.gridfilterDataSource;
      }
    },
    retrievingSchedule () {
      if (!this.retrievingSchedule) {
        this.updateFooterHeight();
      }
    },
    selectedJobTypeTab () {
      this.refreshPanels();
    },
    sidePanelOpened (newValue, oldValue) {
      // This is the global side panel
      if (newValue !== oldValue) {
        this.redrawGrid(true);
      }
    },
    staffFilter: _.debounce(function () {
      this.filterGrid(false);
    }, SEARCH_INPUT_DEBOUNCE),
    mobileSummaryRow () {
      if (this.grid) {
        this.redrawGrid(true);
      }
    },
    openedPanelName (openedPanelName) {
      if (openedPanelName) {
        switch (openedPanelName) {
          case 'filters':
            this.closeAllPanels();
            this.openFiltersPanel();
            break;
          case 'viewOpenShift':
            this.openOpenShiftsListPanel();
            break;
        }
      } else {
        this.closeAllPanels();
        this.closeAllPersistentPanels();
      }
    }
  },
  mounted: function () {
    if (this.selectedScheduleId) {
      this.retrieveScheduleById(this.selectedScheduleId).then((schedule) => {
        this.selectedSchedule = schedule;
        this.retrieveSchedule();
      }).catch(() => {
        if (this.schedulePeriods[0]) {
          this.selectedScheduleId = this.schedulePeriods[0].value;
          this.selectedSchedule = this.schedulePeriods[0];
          this.retrieveSchedule();
        }
      });
    } else {
      if (this.schedulePeriods[0]) {
        this.selectedScheduleId = this.schedulePeriods[0].value;
        this.selectedSchedule = this.schedulePeriods[0];
        this.retrieveSchedule();
      }
    }
    this.$store.commit(
      'add_ws_update_callback',
      { name: 'schedule_page', callback: this.onWsUpdate },
      { root: true }
    );
  },
  destroyed () {
    this.$store.commit('scheduling/set_active_schedule_id', null);
  },
  beforeDestroy: function () {
    this.unbindShortcuts();
    this.destroyGrid();
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit(
      'remove_ws_update_callback',
      'schedule_page',
      { root: true }
    );
    next();
  },
  methods: {
    addEvent (eventId, userId, dates) {
      if (eventId && userId && dates.length > 0) {
        const event = {
          assigneeId: userId,
          departmentId: this.department.id,
          typeId: eventId,
          dates: dates.map((d) => moment(d).format('YYYY-MM-DD'))
        };
        this.dispatch('scheduling/createEvents', [event]).then((events) => {
          event.id = events[0].id;
          event.dates = dates.map((d) => moment(d));
          event.type = 'event';
          this.$store.commit('scheduling/add_event', {
            event
          });
          showStatus({
            text: this.$t('descriptions.eventSaveSuccess')
          });
          this.$nextTick(() => {
            this.redrawGrid();
            this.refreshPanels();
          });
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.eventSaveFail'),
            type: 'error',
            data
          });
        });
      }
    },
    bindShortcuts () {
      this.unbindShortcuts();
      for (let shortcutKey in this.shortcutActions) {
        this.mousetrap.bind(shortcutKey, () => {
          if (this.grid) {
            const cell = this.grid.selection.select;
            if (cell.col !== 0 && cell.row !== 0) {
              const value = this.getCellValue(cell);
              const user = this.getCellUser(cell);
              const date = this.getCellCol(cell);
              if (moment.isMoment(date)) {
                if (this.isDateReadOnly(date)) {
                  return;
                }
                if (value && value.request) {
                  this.showRequest(value.request, true);
                  return;
                }
                this.shortcutActions[shortcutKey].action(this.shortcutActions[shortcutKey], user.userId, date);
              }
            }
          }
        });
      }
      this.mousetrap.bind(['backspace', 'del'], () => {
        if (this.grid) {
          const cell = this.grid.selection.select;
          const value = this.getCellValue(cell);
          if (value) {
            const date = this.getCellCol(cell);
            if (!moment.isMoment(date) || this.isDateReadOnly(date)) {
              return;
            }
            if (value.request) {
              this.showRequest(value.request, true);
              return;
            }
            const user = this.getCellUser(cell);
            // Only allow delete shortcut when there is one activity.
            const activities = _.get(this.records, [this.userRecordMap[user.userId], date.valueOf(), 'activities'], []);
            if (activities.length !== 1) {
              return;
            }
            const activity = activities[0];
            let dates;
            switch (activity.type) {
              case 'shift':
                if (this.scheduleAllowsShiftDeletion) {
                  this.$dialog.confirm({
                    body: this.$t('descriptions.continueDeleteShift'),
                    confirmText: this.$t('labels.delete'),
                    cancelText: this.$t('labels.cancel'),
                    title: this.$t('labels.continueDeleteShift'),
                    titleIcon: 'fal fa-exclamation-triangle'
                  }, { persistent: true, width: 400 }).then(() => {
                    this.dispatch('scheduling/deleteShifts', [activity.id]).then(() => {
                      this.$store.commit('scheduling/remove_shift', activity);
                      this.$nextTick(() => {
                        this.redrawGrid();
                        this.refreshPanels();
                        if (!date.isSame(moment(activity.date))) {
                          this.grid.selectGridCell(moment(activity.date).valueOf(), this.getCellRowByUser(user.userId), true);
                        }
                      });
                      showStatus({
                        text: this.$t('descriptions.shiftDeleteSuccess')
                      });
                    }).catch(error => {
                      const status = _.get(error, 'response.status', '');
                      const data = {
                        error: _.get(error, 'response.data')
                      };

                      let text = '';
                      if (status === ERROR_CODES.http412PreconditionFailed) {
                        text = this.$t('descriptions.shiftDelete412');
                      } else {
                        text = this.$t('descriptions.shiftDeleteFail');
                      }

                      showStatus({
                        text,
                        type: 'error',
                        data
                      });
                    });
                  }).catch(() => {});
                }
                break;
              case 'event':
                dates = _.cloneDeep(activity.dates);
                // When there is only one date in the event then mark the event as delete otherwise update the dates array
                if (dates.length === 1) {
                  this.removeEvent(activity);
                } else {
                  const index = _.findIndex(dates, (dateObj) => moment(dateObj).format('YYYY-MM-DD') === date.format('YYYY-MM-DD'));
                  if (index >= 0) {
                    dates.splice(index, 1);
                    this.updateEvent(activity, { ...activity, dates });
                  }
                }
                break;
            }
          }
        }
      });

      this.mousetrap.bind(['up', 'right', 'down', 'left'], (e, key) => {
        if (this.grid) {
          this.grid.keyDownMove(e);
        }
      });

      this.mousetrap.bind(['enter'], (e, key) => {
        if (this.grid && this.grid.hasFocusGrid()) {
          this.showPanelOnCellSelect = true;
          this.showCellDetails(this.grid.selection.select);
        }
      });

      this.mousetrap.bindGlobal(['mod+/'], (e, key) => {
        this.toggleHelpPanel();
      });
    },
    unbindShortcuts () {
      this.mousetrap.reset();
    },
    removeEvent (event) {
      this.$dialog.confirm({
        body: this.$t('descriptions.continueDeleteEvent'),
        confirmText: this.$t('labels.delete'),
        cancelText: this.$t('labels.cancel'),
        title: this.$t('labels.continueDeleteEvent'),
        titleIcon: 'fal fa-exclamation-triangle'
      }, { persistent: true, width: 400 }).then(() => {
        this.dispatch('scheduling/deleteEvents', [event.id]).then(() => {
          this.$store.commit('scheduling/remove_event', {
            event
          });
          this.$nextTick(function () {
            this.redrawGrid();
            this.refreshPanels();
          });
          showStatus({
            text: this.$t('descriptions.eventDeleteSuccess')
          });
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.eventDeleteFail'),
            type: 'error',
            data
          });
        });
      }).catch(() => {});
    },
    updateEvent (originalEvent, event) {
      this.dispatch('scheduling/updateEvent', {
        id: event.id,
        props: {
          ...event,
          dates: [...event.dates.map(date => moment(date).format('YYYY-MM-DD'))].sort()
        }
      }).then(() => {
        this.$store.commit('scheduling/update_event', {
          event,
          originalEvent
        });
        this.$nextTick(function () {
          this.redrawGrid();
          this.refreshPanels();
        });
        showStatus({
          text: this.$t('descriptions.eventSaveSuccess')
        });
      }).catch(error => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.eventSaveFail'),
          type: 'error',
          data
        });
      }).finally(() => {
        this.saving = false;
      });
    },
    destroyGrid () {
      if (this.grid) {
        this.grid.dispose();
      }
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    downloadSchedule () {
      const pageCount = Math.ceil(this.printRecords.length / this.download.limit[this.download.pageSize]);
      this.download.inProgress = true;
      setTimeout(() => {
        this.$nextTick(() => {
          const content = [];
          let printing = Promise.resolve();
          let page = 0;
          for (let i = 0; i < pageCount; i++) {
            printing = printing.then(() => {
              return new Promise((resolve, reject) => {
                this.download.page = page;
                this.$nextTick(() => {
                  this.renderDownloadGrid().then(() => {
                    this.$nextTick(() => {
                      const canvas = this.$refs.downloadGrid.getElementsByTagName('canvas')[0];
                      const dataURL = canvas.toDataURL('image/jpeg', 1.0);
                      switch (this.download.pageSize) {
                        case 'LEGAL':
                          content.push({
                            image: dataURL,
                            width: 985,
                            height: 2640,
                            margin: [0, 0, 0, 0]
                          });
                          break;
                        case 'LETTER':
                        default:
                          content.push({
                            image: dataURL,
                            width: 772,
                            height: 2640,
                            margin: [0, 0, 0, 0]
                          });
                          break;
                      }
                      page++;
                      resolve();
                    });
                  });
                });
              });
            });
          }
          printing.then(() => {
            const docDefinition = {
              defaultStyle: {
                color: '#000000',
                fontSize: 12,
                lineHeight: 1.2
              },
              styles: {
                pageSubtitle: {
                  alignment: 'center',
                  bold: true,
                  margin: [0, 0, 0, 0]
                },
                pageTitle: {
                  alignment: 'center',
                  bold: true,
                  margin: [0, 10, 0, 0]
                }
              },
              content,
              header: () => {
                return [
                  {
                    stack: [
                      {
                        text: this.department.name,
                        style: ['pageTitle']
                      },
                      {
                        text: this.selectedScheduleText,
                        style: ['pageSubtitle']
                      }
                    ]
                  }
                ];
              },
              pageMargins: [10, 60, 10, 10],
              pageOrientation: 'landscape',
              pageSize: this.download.pageSize
            };
            pdfMake.createPdf(docDefinition).download(`${this.department.name} ${this.selectedScheduleText}`);
            this.download.dialogOpened = false;
          });
        });
      }, 0);
    },
    filterGrid (focusGrid) {
      if (this.grid) {
        const filter = this.getGridFilter();
        let newSelectedRow, field;
        // If a cell is selected by the user check if that cell is still on the grid after the filter
        // and select the cell at it's new index. If the cell is no longer in the grid then reset the
        // focus to the top left cell vertex (0, 0) which corresponds to the first cell of the 'user' column.
        // When we need to select the header row we set then row index to -1 because the focusGridCell
        // function adds 1 to the value we pass in.
        if (this.selectedCell.col) {
          if (moment.isMoment(this.selectedCell.col)) {
            field = this.selectedCell.col.valueOf();
          } else {
            field = this.selectedCell.col;
          }
          if (this.selectedCell.user) {
            const newRow = this.getCellRowByUser(this.selectedCell.user);
            if (newRow >= 0) {
              newSelectedRow = newRow;
            } else {
              newSelectedRow = -1;
              field = 'user';
            }
          } else {
            newSelectedRow = -1;
          }
        }

        this.gridfilterDataSource.filter = filter;
        this.updateGridEmptyMessage();
        this.redrawGrid();
        // Redraw a second time. Redrawing once does not render the grid correctly. Blank rows are being
        // inserted. This behavior can also be observed in the cheetah-grid demo page.
        this.redrawGrid(focusGrid);
        if (field) {
          this.grid.selectGridCell(field, newSelectedRow, focusGrid);
        }
      }
    },
    findShiftById (userId, id) {
      const row = _.get(this.allRecords, [this.allUserRecordMap[userId]], {});
      for (let prop in row) {
        const timestamp = moment(parseInt(prop));
        if (!timestamp.isValid()) {
          continue;
        }

        const shift = _.find(_.get(row[prop], 'activities', []), (a) => {
          return a.type === 'shift' && a.id === id;
        });
        if (shift) {
          return shift;
        }
      }
      return null;
    },
    focusGrid () {
      if (this.grid) {
        this.grid.focus();
      }
    },
    /**
     * Gets the shift date for a cell
     * @param {object} cell cheetah-gird cell
     * @returns {Date}
     */
    getCellCol (cell) {
      const col = this.grid.header[cell.col];
      if (col.type === 'schedule') {
        return moment(col.caption);
      } else {
        return col.field;
      }
    },
    /**
     * Gets the user associated with a cell
     * @param {object} cell cheetah-gird cell
     * @return {object}
     */
    getCellUser (cell) {
      if (cell.row < 1) {
        // ignore header row
        return null;
      }
      // cheetah-grid counts the header row when returning the cell row index
      // therefore subtract 1 to get the correct row in the records array since
      // the records array does not contain the header.
      const row = this.gridfilterDataSource.getOriginal(cell.row - 1);
      return row['user'];
    },
    getCellRowByUser (userId) {
      // This only looks in the set of filtered rows and returns -1 when the row is not found
      const filter = this.getGridFilter();
      let newRow = 0;
      for (let i = 0, len = this.records.length; i < len; i++) {
        if (filter ? filter(this.records[i]) : true) {
          if (this.records[i].user.userId === userId) {
            return newRow;
          }
          newRow++;
        }
      }
      return -1;
    },
    /**
     * Gets the value associated with a cell
     * @param {object} cell cheetah-gird cell
     * @return {object}
     */
    getCellValue (cell) {
      if (cell.row < 1) {
        // ignore header row
        return null;
      }
      const col = this.grid.header[cell.col];
      // cheetah-grid counts the header row when returning the cell row index
      // therefore subtract 1 to get the correct row in the records array since
      // the records array does not contain the header.
      const row = this.gridfilterDataSource.getOriginal(cell.row - 1);
      return row[col.field];
    },
    getGridFilter () {
      const filters = [];
      if (this.selectedJobTypeTab !== this.ALL_JOB_TYPES) {
        let filterForCharge = false;
        const associatedJobTypes = this.getSelectedJobTypes().map((jt) => jt.associatedJobTypes).reduce((accumulator, currentValue) => {
          accumulator.push(...currentValue);
          return accumulator;
        }, []);
        for (let i = 0, count = associatedJobTypes.length; i < count; i++) {
          if (this.jobTypesById[associatedJobTypes[i]] && _.get(this.jobTypesById[associatedJobTypes[i]], 'settings.isChargeNurse', false)) {
            filterForCharge = true;
            break;
          }
        }
        filters.push((record) => {
          return _.indexOf(associatedJobTypes, record.user.jobTypeId) >= 0 ||
            (filterForCharge && record.user.charge);
        });
      }
      const status = _.get(this.filters, 'user.status', []);
      if (status.length > 0) {
        filters.push((record) => {
          return status.includes(record.user.jobStatusId);
        });
      }
      const shiftTypes = _.get(this.filters, 'user.shiftTypes', []);
      if (shiftTypes.length > 0) {
        filters.push((record) => {
          return shiftTypes.includes(record.user.shiftTypeId);
        });
      }
      if (this.staffFilter) {
        const text = this.staffFilter.toLowerCase();
        filters.push((record) => {
          return userMatchesText(record.user, text);
        });
      }

      const filtersCount = filters.length;
      let filter = null;
      if (filtersCount > 0) {
        filter = (record) => {
          if (_.isEmpty(record)) {
            return false;
          }
          let match = true;
          for (let i = 0; i < filtersCount; i++) {
            if (_.isFunction(filters[i])) {
              match &= filters[i](record);
            } else {
              match &= _.get(record, filters[i].key, null) === filters[i].value;
            }
          }
          return match;
        };
      }
      return filter;
    },
    getJobTypeTabIndex (jobId) {
      for (let i = 0, count = this.groupedJobTypes.length; i < count; i++) {
        const matchedJobType = _.find(this.groupedJobTypes[i], (jobType) => {
          return jobType.id == jobId;
        });
        if (matchedJobType) {
          return i;
        }
      }
      return -1;
    },
    getScheduleStateIndicator (schedule, mobile) {
      const stateIndicator = {
        date: '',
        stateDescCssClass: 'grey--text text--darken-1'
      };
      switch (schedule.state) {
        case SCHEDULE_STATES.PENDING_POST_APPROVAL:
        case SCHEDULE_STATES.PENDING_PUBLISH_APPROVAL:
          stateIndicator.stateLabelCssClass = 'error';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStatePendingApprovalDirectorAbbr' : 'labels.scheduleStatePendingApprovalDirector';
          stateIndicator.stateDescCssClass = 'grey--text text--darken-1';
          stateIndicator.stateDescKey = this.scheduleIsReadOnly ? 'descriptions.readOnlyMode' : 'descriptions.scheduleStatePendingApprovalDirector';
          break;

        case SCHEDULE_STATES.UNDER_NURSE_REVIEW:
          stateIndicator.stateLabelCssClass = 'info';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStateUnderReviewNurseAbbr' : 'labels.scheduleStateUnderReviewNurse';
          stateIndicator.stateDescCssClass = 'info--text';
          stateIndicator.stateDescKey = 'descriptions.scheduleUnderNurseReviewUntil';
          if (this.schedulePublishDueDate.isSame(moment(), 'year')) {
            stateIndicator.date = this.schedulePublishDueDate.format(this.dateFormatShort);
          } else {
            // Only displays year info when the due date is not in the current year.
            stateIndicator.date = this.schedulePublishDueDate.format(this.dateFormatLong);
          }
          break;

        case SCHEDULE_STATES.PUBLISHED:
          stateIndicator.stateLabelCssClass = 'success';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStatePublishedAbbr' : 'labels.scheduleStatePublished';
          stateIndicator.stateDescCssClass = 'grey--text text--darken-1';
          stateIndicator.stateDescKey = this.scheduleIsReadOnly ? 'descriptions.readOnlyMode' : 'descriptions.scheduleStatePublished';
          break;

        case SCHEDULE_STATES.DRAFT:
          stateIndicator.stateLabelCssClass = 'nb-orange';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStateDraftAbbr' : 'labels.scheduleStateDraft';
          stateIndicator.stateDescKey = this.scheduleIsReadOnly ? 'descriptions.readOnlyMode' : 'descriptions.scheduleStateDraft';
          break;
        case SCHEDULE_STATES.SELF_SCHEDULE:
          stateIndicator.stateLabelCssClass = 'nb-purple';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStateSelfScheduleAbbr' : 'labels.scheduleStateSelfSchedule';
          stateIndicator.stateDescKey = this.scheduleIsReadOnly ? 'descriptions.readOnlyMode' : 'descriptions.scheduleStateSelfSchedule';
          break;
        case SCHEDULE_STATES.CURRENT:
          stateIndicator.stateLabelCssClass = 'success';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStateCurrentAbbr' : 'labels.scheduleStateCurrent';
          stateIndicator.stateDescKey = this.scheduleIsReadOnly ? 'descriptions.readOnlyMode' : 'descriptions.scheduleStateCurrent';
          break;
        case SCHEDULE_STATES.INITIAL:
          stateIndicator.stateLabelCssClass = 'nb-purple';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStateInitialAbbr' : 'labels.scheduleStateInitial';
          stateIndicator.stateDescKey = this.scheduleIsReadOnly ? 'descriptions.readOnlyMode' : 'descriptions.scheduleStateInitial';
          break;
        case SCHEDULE_STATES.PAST:
          stateIndicator.stateLabelCssClass = 'grey darken-1';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStatePastAbbr' : 'labels.scheduleStatePast';
          stateIndicator.stateDescKey = this.scheduleIsReadOnly ? 'descriptions.readOnlyMode' : 'descriptions.scheduleStatePast';
          break;
        default:
          stateIndicator.stateLabelCssClass = '';
          stateIndicator.stateLabelKey = '';
          stateIndicator.stateDescKey = '';
      }

      return stateIndicator;
    },
    getSelectedJobTypes () {
      return this.groupedJobTypes[this.selectedJobTypeTab];
    },
    getSelectedAssociatedJobTypeIds () {
      return _.uniq(this.groupedJobTypes[this.selectedJobTypeTab].reduce((jobTypes, jt) => {
        jobTypes.push(...jt.associatedJobTypes);
        return jobTypes;
      }, []));
    },
    getSelectedJobTypeIds () {
      if (this.groupedJobTypes[this.selectedJobTypeTab]) {
        return this.groupedJobTypes[this.selectedJobTypeTab].map((jt) => jt.id);
      }
      return [];
    },
    getShiftTypeIdsForUser (user) {
      const jobType = this.jobTypesById[user.jobTypeId];
      const shiftTypes = _.filter(this.$store.state.org.shiftTypes, (shiftType) => {
        return _.indexOf(jobType.settings.associatedShiftTypes, shiftType.id) >= 0;
      });

      if (user.charge) {
        const chargeJobTypes = _.filter(this.$store.state.org.jobTypes, (jt) => {
          return _.get(jt, 'settings.isChargeNurse', false);
        });
        for (let jt of chargeJobTypes) {
          const associatedShiftTypes = _.get(jt, 'settings.associatedShiftTypes', []);
          for (let id of associatedShiftTypes) {
            if (this.shiftTypesById[id]) {
              shiftTypes.push(this.shiftTypesById[id]);
            }
          }
        }
      }

      return _.uniq(shiftTypes.map((st) => st.id));
    },
    /**
     * Gets event request details
     * @param {object} request Request metadata
     * @return {object}
     */
    getEventRequestDetails (request) {
      return {
        ...request,
        user: this.allRecords[this.allUserRecordMap[request.assigneeId]].user,
        type: 'event'
      };
    },
    getShiftRequestDetails (request) {
      return {
        ...request,
        user: this.allRecords[this.allUserRecordMap[request.assigneeId]].user,
        type: 'shift'
      };
    },
    /**
     * Gets swap request details
     * @param {object} request Request metadata
     * @param {object} request.sourceUserId Requestor user ID
     * @param {object} request.sourceShiftSlotId Requestor assigned shift slot
     * @param {object} request.targetUserId Exchanger user ID
     * @param {object} request.targetShiftSlotId Exchanger assigned shift slot
     * @return {object}
     */
    getSwapRequestDetails (request) {
      return {
        ...request,
        sourceUser: this.$store.state.org.employees[request.sourceUserId],
        targetUser: this.$store.state.org.employees[request.targetUserId],
        type: 'swap'
      };
    },
    getValidatorName (validator) {
      return validator.constructor.name;
    },
    getValidatorErrorCount (validator) {
      let count = 0;
      const jobTypesById = this.$store.getters['scheduling/getJobTypesById'](this.selectedScheduleId);
      let jobTypeIds = _.keys(jobTypesById);
      if (this.selectedJobTypeTab !== this.ALL_JOB_TYPES) {
        jobTypeIds = this.getSelectedJobTypeIds();
      }
      for (let id of jobTypeIds) {
        if (this.hasSelectedShiftTypes) {
          for (let shiftTypeId of this.selectedShiftTypes) {
            if (_.indexOf(_.get(jobTypesById, [id, 'associatedShiftTypes'], []), shiftTypeId) >= 0) {
              count += validator.errorCount(
                parseInt(id),
                parseInt(shiftTypeId)
              );
            }
          }
        } else {
          count += validator.errorCount(
            parseInt(id)
          );
        }
      }
      return count;
    },
    jobTypeById (id, prop) {
      return this.$store.getters['org/getJobTypeById'](id, prop);
    },
    /**
     * Returns a value indicating whether there is (specific) validation error.
     * @param {string} (Optional) a path corresponding to a specific error to check. @see https://lodash.com/docs/4.17.15#has
     * @returns {Boolean} true if there is (specific) validation error; otherwise false.
     */
    hasValidationErrors (job) {
      let errorCount = 0;

      if (job) {
        errorCount = this.$store.getters['scheduling/getErrorCount'](this.selectedScheduleId, job);
      } else {
        errorCount = this.totalErrorCount;
      }

      return errorCount > 0;
    },
    initGrid () {
      let self = this;
      if (this.grid) {
        this.grid.dispose();
        this.grid = null;
      }
      cheetahGrid.themes.default = cheetahGrid.themes.default.extends({
        font: DEFAULT_FONT_SIZE_TABLE + ' ' + DEFAULT_FONT_FAMILY
      });

      const theme = cheetahGrid.themes.SCHEDULER.extends({
        holidayBgColor: _.get(this.$store.state.org, ['settings', 'scheduling', 'holidays', 'styles', 'web', 'bgColor'], null)
      });

      this.updateGridHeight();

      const validators = this.$store.getters['scheduling/getValidator'](this.selectedScheduleId);
      const getValidationStyle = (shift) => {
        const styles = {};
        for (let name in validators) {
          _.merge(styles, validators[name].style(shift));
        }
        if (shift.overtime) {
          styles.color = scssVariables.error;
        }
        return styles;
      };
      const canceledBgColor = _.get(self.scheduleSymbols, ['shift', 'web', 'canceled', 'bgColor'], null);
      const onCallBgColor = _.get(self.scheduleSymbols, ['shift', 'web', 'onCall', 'bgColor'], null);
      const sitterBgColor = _.get(self.scheduleSymbols, ['shift', 'web', 'sitter', 'bgColor'], null);
      const sameColumns = (column1, column2) => {
        if (moment.isMoment(column1) && moment.isMoment(column2)) {
          return column1.isSame(column2);
        } else {
          return column1 != column2;
        }
      };
      this.$nextTick(() => {
        this.grid = new cheetahGrid.ScheduleGrid({
          parentElement: this.$refs.gridParent,
          onBeforeSelect: (cell) => {
            return new Promise((resolve, reject) => {
              const user = this.getCellUser(cell);
              const col = this.getCellCol(cell);
              const userId = user ? user.userId : null;
              if (this.hasChanges && (!sameColumns(col, this.selectedCell.col) || userId != this.selectedCell.user)) {
                this.$dialog.confirm(getUnsavedChangesDialogProps(this)).then(() => {
                  reject(new Error('Unsaved changes'));
                }).catch(() => {
                  this.hasChanges = false;
                  this.$store.commit('unmark_all_unsaved_changes');
                  resolve();
                });
              } else {
                resolve();
              }
            });
          },
          onResize: () => {
            this.redrawGrid();
          },
          emptyMessage: this.emptyMessage,
          header: this.headers,
          footer: this.footers,
          firstDayOfWeek: 0,
          frozenColCount: this.FROZEN_COLUMN_COUNT,
          language: this.gridLanguage,
          showStaffPerDay: true,
          showWeeklyBalance: false,
          symbols: {
            borderColor (cell) {
              const shifts = _.filter(_.get(cell, 'activities', []), (activity) => activity.type === 'shift');
              for (let i = 0, shiftCount = shifts.length; i < shiftCount; i++) {
                const validationStyle = getValidationStyle(shifts[i]);
                if (_.has(validationStyle, 'borderColor')) {
                  return validationStyle.borderColor;
                }
              }
              return null;
            },
            bgColor (cell) {
              const shifts = _.filter(_.get(cell, 'activities', []), (activity) => activity.type === 'shift');
              for (let i = 0, shiftCount = shifts.length; i < shiftCount; i++) {
                const validationStyle = getValidationStyle(shifts[i]);
                if (_.has(validationStyle, 'bgColor')) {
                  return validationStyle.bgColor;
                }
              }
              return null;
            },
            event (value) {
              return _.get(self.eventTypes, [value.typeId, 'styles', 'web'], {});
            },
            shift (shift) {
              if (!self.shiftTypes[shift.typeId]) {
                return {};
              }
              let style = _.cloneDeep(_.get(self.shiftTypes, [shift.typeId, 'styles', 'web'], {}));
              if (shift.available) {
                style = _.cloneDeep(self.scheduleSymbols.shift.web.available);
              } else {
                const shiftType = self.shiftTypes[shift.typeId];
                let startTime = shift.startTime ? shift.startTime : shiftType.startTime;
                let endTime = shift.endTime ? shift.endTime : shiftType.endTime;
                if ((startTime !== shiftType.startTime || endTime !== shiftType.endTime) || style.symbolType === 'time') {
                  let start = startTime.substring(0, 5);
                  let end = endTime.substring(0, 5);
                  if (shift.obligatory) {
                    start += '*';
                    end += '  ';
                  }
                  style.symbolType = 'time';
                  style.symbolValue = { start, end };
                }
              }

              if (shift.departmentId !== self.department.id && self.allDepartments[shift.departmentId]) {
                const departmentName = self.allDepartments[shift.departmentId].name;
                style.symbolType = 'text';
                style.symbolValue = departmentName;
              }

              if (shift.obligatory) {
                if (_.get(style, 'symbolType', '') === 'text') {
                  style.symbolValue += '*';
                }
              }

              const validationStyle = getValidationStyle(shift);
              // Validation background color will be applied to the whole cell not individual shifts.
              delete validationStyle.bgColor;
              let bgColor = null;
              if (shift.sitter) {
                bgColor = sitterBgColor;
              } else if (shift.canceled) {
                bgColor = canceledBgColor;
              } else if (shift.onCall) {
                bgColor = onCallBgColor;
              } else if (!isProductiveShift(shift, self.shiftFlags)) {
                bgColor = canceledBgColor;
              }

              return {
                ...style,
                ...validationStyle,
                bgColor,
                modified: wasShiftModifiedByManagement(shift, self.$store.state),
                differentPayrollDate: shift.payrollDate && !moment(shift.payrollDate).isSame(moment(shift.date)),
                strikeThrough: !isWorkingShiftForDisplay(shift, self.shiftFlags)
              };
            },
            request (value) {
              return _.get(self.$store.state.org, ['settings', 'scheduling', 'symbols', 'request', 'web', 'pendingApprovalOperator'], {
                color: '#FF5378'
              });
            },
            week (user, week) {
              let style = {};
              if (validators['OvertimeValidator']) {
                style = validators['OvertimeValidator'].weekStyle(user, week);
              }
              return {
                bgColor: '#F5F5F5',
                ...style
              };
            }
          },
          theme
        });

        setTimeout(() => {
          if (this.todaysColumnIndex >= 0) {
            this.gridState.todaysRectangle = this.grid.getCellsRect(this.todaysColumnIndex, 0, this.todaysColumnIndex, 0);
          }
        }, 0);

        const { CLICK_CELL, SCROLL, SELECTED_CELL, CLICK_USER_NAME, CLICK_USER_SETTINGS } = cheetahGrid.ScheduleGrid.EVENT_TYPE;
        this.grid.listen(CLICK_CELL, (cell) => {
          // At this moment we do not allow clicking on the user column therefore ignore the click
          if (cell.col > 0) {
            this.showPanelOnCellSelect = true;
            this.showCellDetails(cell);
          }
        });

        this.grid.listen(SELECTED_CELL, (cell) => {
          const user = this.getCellUser(cell);
          const col = this.getCellCol(cell);
          const userId = user ? user.userId : null;
          if (cell.selected && !this.hasChanges && (!sameColumns(col, this.selectedCell.col) || userId != this.selectedCell.user)) {
            this.showCellDetails(cell);
          }
        });

        this.grid.listen(SCROLL, (e) => {
          this.gridState.canScrollLeft = this.grid.canScrollLeft();
          this.gridState.canScrollRight = this.grid.canScrollRight();
          this.gridState.scrollLeft = e.event.target.scrollLeft;
        });

        this.grid.listen(CLICK_USER_SETTINGS, (cell) => {
          const user = this.getCellUser(cell);
          if (this.hasChanges) {
            this.$dialog.confirm(getUnsavedChangesDialogProps(this)).then(() => {}).catch(() => {
              this.hasChanges = false;
              this.$store.commit('unmark_all_unsaved_changes');
              this.showNurseDetails(user.userId);
              setTimeout(() => {
                this.grid.setSelectedUserSettings(cell.row);
              }, 0);
            });
          } else {
            this.showNurseDetails(user.userId);
            setTimeout(() => {
              this.grid.setSelectedUserSettings(cell.row);
            }, 0);
          }
        });

        this.grid.listen(CLICK_USER_NAME, (cell) => {
          this.openNurseDetails(this.getCellUser(cell));
        });

        this.gridfilterDataSource = new cheetahGrid.data.FilterDataSource({
          get (index) {
            return self.records[index] || {};
          },
          length: self.records.length
        });
        this.gridfilterDataSource.filter = this.getGridFilter();
        this.grid.dataSource = this.gridfilterDataSource;
        this.gridState.canScrollLeft = this.grid.canScrollLeft();
        this.gridState.canScrollRight = this.grid.canScrollRight();

        this.bindShortcuts();
      });
    },
    isDateReadOnly (date) {
      return this.scheduleIsReadOnly;
    },
    setSelectedSchedule (schedule) {
      if (this.hasChanges) {
        this.$dialog.confirm(getUnsavedChangesDialogProps(this)).then(() => {}).catch(() => {
          this.hasChanges = false;
          this.$store.commit('unmark_all_unsaved_changes');
          this.retrievingSchedule = true;
          this.showSchedulePicker = false;
          if (this.grid) {
            this.grid.dispose();
            this.grid = null;
          }
          this.$nextTick(() => {
            this.selectedScheduleId = schedule.id;
            this.selectedSchedule = schedule;
            this.$store.commit('scheduling/set_active_schedule_id', schedule.id);
            this.closeAllPanels();
            this.retrieveSchedule();
          });
        });
      } else {
        this.retrievingSchedule = true;
        this.showSchedulePicker = false;
        if (this.grid) {
          this.grid.dispose();
          this.grid = null;
        }
        this.$nextTick(() => {
          this.selectedScheduleId = schedule.id;
          this.selectedSchedule = schedule;
          this.$store.commit('scheduling/set_active_schedule_id', schedule.id);
          this.closeAllPanels();
          this.retrieveSchedule();
        });
      }
    },
    moment,
    onJobTypeChange (idx) {
      if (this.hasChanges && !this.isSelectedCellInViewAfterFilter({ 'selectedJobTypeTab': idx })) {
        this.$dialog.confirm(getUnsavedChangesDialogProps(this)).then(() => {}).catch(() => {
          this.hasChanges = false;
          this.$store.commit('unmark_all_unsaved_changes');
          this.closeLastPanel();
          this.selectedJobTypeTab = idx;
          this.filterGrid(true);
        });
      } else {
        this.selectedJobTypeTab = idx;
        this.filterGrid(true);
      }
    },
    redrawGrid (focusGrid) {
      if (this.grid) {
        this.updateGridHeight();
        // After updating the grid height we need to wait for vue to finished re-evaluating
        // any grid height dependencies so that cheetah-grid can calculate the correct width
        // and height for the grid.
        this.$nextTick(() => {
          this.grid.header = this.headers;
          this.grid.footer = this.footers;
          this.grid.resize();
          if (focusGrid) {
            this.focusGrid();
          }
          setTimeout(() => {
            if (this.todaysColumnIndex >= 0) {
              this.gridState.todaysRectangle = this.grid.getCellsRect(this.todaysColumnIndex, 0, this.todaysColumnIndex, 0);
            }
          }, 0);
        });
      }
    },
    retrieveSchedule () {
      this.retrievingSchedule = true;
      this.showSchedulePicker = false;
      this.openedPanelName = undefined;
      this.openShiftDate = '';
      const criteria = {
        scheduleId: this.selectedScheduleId,
        scheduleStartDate: this.selectedSchedule.startOn,
        scheduleEndDate: this.selectedSchedule.endOn,
        deptId: this.department.id
      };
      if (this.grid) {
        this.grid.dispose();
        this.grid = null;
      }
      const action = this.selectedScheduleId ? 'scheduling/retrieveSchedule' : 'scheduling/retrievePseudoSchedule';
      this.dispatch(action, criteria).then((scheduleId) => {
        // DO NOT move the code below to 'finally' block like other places.
        // We need to immediately hide the skeleton loader upon receiving response
        // from the web API and render the parent container for the grid. Otherwise
        // the grid won't get initialized.
        this.retrievingSchedule = false;
        // Wait for the next tick to ensure the skeleton loader is hidden and the
        // parent container for the grid is rendered.
        this.$nextTick(() => {
          this.initGrid();
          const request = this.$store.state.scheduling.activeRequest;
          if (request) {
            // Need to wait for the grid to finish loading before opening the panel otherwise the grid
            // width will not adjust correctly. NextTick doesnt work here since cheetah grid is not a vue
            // component so use setTimeout to let the cheetah grid task finish before showing the panel.
            setTimeout(() => {
              this.showRequest(_.cloneDeep(request), true, 'errors');
            }, 0);
            this.$store.commit('scheduling/set_active_request', null);
          }
        });
        this.$store.commit('scheduling/set_active_schedule_id', this.selectedScheduleId);
      }).catch(error => {
        Sentry.captureException(error);
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.scheduleRetrievalFail'),
          type: 'error',
          data
        });
      });
    },
    approveSchedule () {
      if (this.needsReview && !this.approvingSchedule) {
        let approveSchedule = null;
        this.approvingSchedule = true;
        switch (this.state) {
          case SCHEDULE_STATES.PENDING_POST_APPROVAL:
            approveSchedule = () => {
              this.$dialog.confirm({
                body: this.$t('descriptions.schedulePostApprove', { 'date': this.schedulePublishDueDate.format(this.dateFormatLong) }),
                confirmText: this.$t('labels.confirm'),
                cancelText: this.$t('labels.cancel'),
                title: this.$t('headlines.readyToPost'),
                titleIcon: 'fal fa-exclamation-triangle'
              }, { persistent: true, width: 400 }).then(() => {
                this.dispatch('scheduling/approveSchedule', { id: this.selectedScheduleId, state: this.schedule.state }).then((response) => {
                  this.approvingSchedule = false;
                  showStatus({
                    text: this.$t('descriptions.schedulePostSuccess')
                  });
                }).catch(error => {
                  this.approvingSchedule = false;
                  const data = {
                    error: _.get(error, 'response.data')
                  };

                  showStatus({
                    text: this.$t('descriptions.schedulePostFail'),
                    type: 'error',
                    data
                  });
                });
              }).catch(() => {
                this.approvingSchedule = false;
              });
            };
            break;
          case SCHEDULE_STATES.PENDING_PUBLISH_APPROVAL:
            approveSchedule = () => {
              this.$dialog.confirm({
                body: this.$t('descriptions.schedulePublishApprove'),
                confirmText: this.$t('labels.confirm'),
                cancelText: this.$t('labels.cancel'),
                title: this.$t('headlines.readyToPublish'),
                titleIcon: 'fal fa-exclamation-triangle'
              }, { persistent: true, width: 400 }).then(() => {
                this.dispatch('scheduling/approveSchedule', { id: this.selectedScheduleId, state: this.schedule.state }).then((response) => {
                  this.approvingSchedule = false;
                  showStatus({
                    text: this.$t('descriptions.schedulePublishSuccess')
                  });
                }).catch(error => {
                  this.approvingSchedule = false;
                  const data = {
                    error: _.get(error, 'response.data')
                  };

                  showStatus({
                    text: this.$t('descriptions.schedulePublishFail'),
                    type: 'error',
                    data
                  });
                });
              }).catch(() => {
                this.approvingSchedule = false;
              });
            };
            break;
        }

        if (this.hasValidationErrors()) {
          this.$dialog.confirm({
            body: this.$t('descriptions.schedulePostWithErrors'),
            confirmText: this.$t('labels.continue'),
            cancelText: this.$t('labels.cancel'),
            title: this.$t('headlines.scheduleContinueWithErrors'),
            titleIcon: 'fal fa-exclamation-triangle'
          }, { persistent: true }).then(() => {
            approveSchedule();
          }).catch(() => {
            this.approvingSchedule = false;
          });
        } else {
          approveSchedule();
        }
      }
    },
    rejectSchedule () {
      if (this.needsReview && !this.rejectingSchedule) {
        this.rejectingSchedule = true;
        this.showPanel(() => {
          return {
            component: ReviewComments,
            props: {
              comments: this.schedule.comments
            },
            events: {
              close: () => {
                this.rejectingSchedule = false;
                return true;
              },
              reject: (comments) => {
                this.dispatch('scheduling/rejectSchedule', { id: this.selectedScheduleId, comments, state: this.schedule.state }).then((response) => {
                  this.rejectingSchedule = false;
                  showStatus({
                    text: this.$t('descriptions.scheduleRejectSuccess')
                  });
                  this.closeLastPanel();
                }).catch(error => {
                  this.rejectingSchedule = false;
                  const data = {
                    error: _.get(error, 'response.data')
                  };

                  showStatus({
                    text: this.$t('descriptions.scheduleRejectFail'),
                    type: 'error',
                    data
                  });
                });
              }
            }
          };
        }, [], true);
      }
    },
    postSchedule () {
      if (this.scheduleIsDraft && !this.postingSchedule) {
        this.postingSchedule = true;
        const postSchedule = () => {
          const needsApproval = this.scheduleStates.includes(SCHEDULE_STATES.PENDING_POST_APPROVAL);
          this.$dialog.confirm({
            body: this.$t(needsApproval ? 'descriptions.schedulePost' : 'descriptions.schedulePostApprove', { 'date': this.schedulePublishDueDate.format(this.dateFormatLong) }),
            confirmText: this.$t('labels.confirm'),
            cancelText: this.$t('labels.cancel'),
            title: this.$t('headlines.readyToPost'),
            titleIcon: 'fal fa-exclamation-triangle'
          }, { persistent: true, width: 400 }).then(() => {
            const data = {
              scheduleId: this.selectedScheduleId,
              needsApproval
            };
            this.dispatch('scheduling/postSchedule', data).then((response) => {
              this.postingSchedule = false;
              this.updateGrid();
              showStatus({
                text: this.$t(needsApproval ? 'descriptions.scheduleSentForDirectorApprovalPost' : 'descriptions.schedulePostSuccess')
              });
            }).catch(error => {
              this.postingSchedule = false;
              const data = {
                error: _.get(error, 'response.data')
              };

              showStatus({
                text: this.$t('descriptions.schedulePostFail'),
                type: 'error',
                data
              });
            });
          }).catch(() => {
            this.postingSchedule = false;
          });
        };

        if (this.hasValidationErrors()) {
          this.$dialog.confirm({
            body: this.$t('descriptions.schedulePostWithErrors'),
            confirmText: this.$t('labels.continue'),
            cancelText: this.$t('labels.cancel'),
            title: this.$t('headlines.scheduleContinueWithErrors'),
            titleIcon: 'fal fa-exclamation-triangle'
          }, { persistent: true }).then(() => {
            postSchedule();
          }).catch(() => {
            this.postingSchedule = false;
          });
        } else {
          postSchedule();
        }
      }
    },
    postScheduleForSelfSchedule () {
      if (this.scheduleIsInitial && !this.postingScheduleForSelfSchedule) {
        this.postingScheduleForSelfSchedule = true;
        this.$dialog.confirm({
          body: this.$t('descriptions.scheduleSelfSchedule', { date: this.draftStartDate }),
          confirmText: this.$t('labels.confirm'),
          cancelText: this.$t('labels.cancel'),
          title: this.$t('headlines.readyForSelfSchedule')
        }, { persistent: true, width: 400 }).then(() => {
          this.postingScheduleForSelfSchedule = false;
          this.dispatch('scheduling/postScheduleForSelfSchedule', this.selectedScheduleId).then(() => {
            this.postingScheduleForSelfSchedule = false;
            showStatus({
              text: this.$t('descriptions.schedulePostedForSelfSchedule')
            });
          }).catch(error => {
            this.postingScheduleForSelfSchedule = false;
            const data = {
              error: _.get(error, 'response.data')
            };

            showStatus({
              text: this.$t('descriptions.schedulePostFail'),
              type: 'error',
              data
            });
          });
        }).catch(() => {
          this.postingScheduleForSelfSchedule = false;
        });
      }
    },
    publishSchedule () {
      if (this.scheduleIsReadyToPublish && !this.publishingSchedule) {
        this.publishingSchedule = true;
        const publishSchedule = () => {
          const needsApproval = this.scheduleStates.includes(SCHEDULE_STATES.PENDING_PUBLISH_APPROVAL);
          this.$dialog.confirm({
            body: this.$t(needsApproval ? 'descriptions.schedulePublish' : 'descriptions.schedulePublishApprove'),
            confirmText: this.$t('labels.confirm'),
            cancelText: this.$t('labels.cancel'),
            title: this.$t('headlines.readyToPublish'),
            titleIcon: 'fal fa-exclamation-triangle'
          }, { persistent: true, width: 400 }).then(() => {
            const data = {
              scheduleId: this.selectedScheduleId,
              needsApproval
            };
            this.dispatch('scheduling/publishSchedule', data).then((response) => {
              this.publishingSchedule = false;
              showStatus({
                text: this.$t(needsApproval ? 'descriptions.scheduleSentForDirectorApprovalPublish' : 'descriptions.schedulePublishSuccess')
              });
            }).catch(error => {
              this.publishingSchedule = false;
              const data = {
                error: _.get(error, 'response.data')
              };

              showStatus({
                text: this.$t('descriptions.schedulePublishFail'),
                type: 'error',
                data
              });
            });
          }).catch(() => {
            this.publishingSchedule = false;
          });
        };

        if (this.hasValidationErrors()) {
          this.$dialog.confirm({
            body: this.$t('descriptions.schedulePostWithErrors'),
            confirmText: this.$t('labels.continue'),
            cancelText: this.$t('labels.cancel'),
            title: this.$t('headlines.scheduleContinueWithErrors'),
            titleIcon: 'fal fa-exclamation-triangle'
          }, { persistent: true }).then(() => {
            publishSchedule();
          }).catch(() => {
            this.publishingSchedule = false;
          });
        } else {
          publishSchedule();
        }
      }
    },
    getDailySummaryShiftsData (date) {
      const filter = this.getGridFilter();
      const summaryData = [];
      const field = date.valueOf();
      for (let i = 0, len = this.records.length; i < len; i++) {
        const matches = filter ? filter(this.records[i]) : true;
        if (matches) {
          const activities = _.get(this.records[i], [field, 'activities'], []);
          if (activities.length > 0) {
            summaryData.push({
              date,
              details: this.records[i][field],
              user: this.records[i].user
            });
          }
        }
      }
      return summaryData;
    },
    getDailySummaryRequestsData (date) {
      const filter = this.getGridFilter();
      const summaryData = [];
      const field = date.valueOf();
      for (let i = 0, len = this.records.length; i < len; i++) {
        const matches = filter ? filter(this.records[i]) : true;
        const hasRequest = _.get(this.records[i], [field, 'request', 'id'], 0) > 0;
        if (matches && hasRequest) {
          summaryData.push({
            date,
            details: this.records[i][field].request,
            user: this.records[i].user
          });
        }
      }
      return summaryData;
    },
    /**
     * Shows cell details
     * @param {object} cell cheetah-grid cell
     * @param {int} cell.row Cell row index
     * @param {int} cell.col Cell column index
     */
    showCellDetails (cell) {
      if (cell.col > 0) {
        if (cell.row > 0) {
          const value = this.getCellValue(cell);
          const activities = _.get(value, ['activities'], []);
          const exclude = _.get(value, ['exclude'], false);
          const requestId = _.get(value, ['request', 'id'], null);
          const previousCol = String(_.get(this.selectedCell, 'col', null));
          const previousUser = _.get(this.selectedCell, 'user', null);
          this.selectedCell.col = this.getCellCol(cell);
          this.selectedCell.user = this.getCellUser(cell).userId;
          if (this.showPanelOnCellSelect && this.headers[cell.col]) {
            const header = this.headers[cell.col];
            if (header.type === 'schedule') {
              if (requestId) {
                if (String(this.selectedCell.col) !== previousCol || this.selectedCell.user !== previousUser || this.panels.length === 0) {
                  this.showRequest(value.request, true);
                }
              } else {
                if (this.isDateReadOnly(this.selectedCell.col) || exclude) {
                  if (activities.length > 0) {
                    this.showActivityDetails({ cell }, true);
                  } else {
                    this.showEmptyPanel(this.selectedCell.col);
                  }
                } else {
                  this.showActivityDetails({ cell }, true);
                }
              }
            } else {
              this.showUserWeeklySummary(this.getCellUser(cell), header);
            }
          }
        } else if (cell.row === 0) {
          this.selectedCell.col = this.getCellCol(cell);
          this.selectedCell.user = null;
          if (this.showPanelOnCellSelect) {
            const header = this.headers[cell.col];
            if (header.type === 'schedule') {
              this.showDailySummary(this.getCellCol(cell), true);
            } else {
              this.showWeeklySummary(header);
            }
          }
        }
      } else {
        this.selectedCell.col = null;
        this.selectedCell.user = null;
        if (this.showPanelOnCellSelect) {
          this.showSelectCellPanel();
        }
      }
    },
    /**
     * Shows request details
     * @param {object} request Request object i.e. swap, vacation ...
     */
    showRequest (request, resetPanels, display) {
      this.showPanel(this.getRequestPanelData, [request, display], resetPanels);
    },
    getRequestPanelData (request, display) {
      let component = null;
      let events = {};
      const updateGrid = () => {
        this.redrawGrid();
        this.closeLastPanel();
        this.refreshPanels();
      };
      switch (request.type) {
        case 'shift':
          component = ShiftRequest;
          events = {
            'approved': () => {
              updateGrid();
            },
            'rejected': () => {
              updateGrid();
            }
          };
          break;
        case 'swap':
          component = SwapRequest;
          events = {
            'approved': () => {
              updateGrid();
            },
            'rejected': () => {
              updateGrid();
            },
            'requested-director-approval': () => {
              updateGrid();
            }
          };
          break;
        case 'event':
          component = EventRequest;
          events = {
            'approved': () => {
              updateGrid();
            },
            'rejected': () => {
              updateGrid();
            },
            'requested-director-approval': () => {
              updateGrid();
            }
          };
          break;
      }

      return {
        component,
        props: {
          display,
          requestId: request.id,
          scheduleId: this.selectedScheduleId
        },
        events
      };
    },
    showActivityDetails (schedule, resetPanels) {
      this.showPanel(
        this.getActivityDetailsPanelData,
        [schedule],
        resetPanels
      );
    },
    getActivityDetailsPanelData (schedule) {
      let date, details, user;
      if (schedule.cell) {
        try {
          date = this.getCellCol(schedule.cell);
          details = this.getCellValue(schedule.cell);
          user = this.getCellUser(schedule.cell);
        } catch {
          // An error can happen if the cell is no on the grid due to filtering, in taht case
          // we display the empty cell panel.
          return {
            component: EmptyCell,
            props: {
              date
            },
            events: {}
          };
        }
      } else {
        date = schedule.date;
        details = schedule.details;
        user = schedule.user;
      }

      const activities = _.cloneDeep(_.get(details, 'activities', []));
      const shifts = [];
      const events = [];
      for (let i = 0, activityCount = activities.length; i < activityCount; i++) {
        switch (activities[i].type) {
          case 'shift':
            shifts.push(activities[i]);
            break;
          case 'event':
            events.push(activities[i]);
            break;
        }
      }

      const shiftCount = shifts.length;
      const eventCount = events.length;
      if (shiftCount === 0 && eventCount === 0) {
        return {
          component: ActivitySelection,
          props: {
            date,
            scheduleId: this.selectedScheduleId,
            user
          },
          events: {
            'close': () => {
              if (this.panels.length > 1) {
                this.refreshPanels(this.panels.length - 1);
              }
              return true;
            },
            'add-event': (event) => {
              this.addEvent(event.typeId, user.userId, event.dates);
            },
            'add-shift': (shift) => {
              this.showShiftDetails({
                date,
                selectedShiftIndex: shifts.length,
                shifts: [...shifts, shift],
                user
              }, true);
            }
          }
        };
      } else if (eventCount > 1 || (shiftCount > 0 && eventCount > 0)) {
        return {
          component: ActivityDetails,
          props: {
            date,
            events,
            shifts,
            user
          },
          events: {
            'close': () => {
              if (this.panels.length > 1) {
                this.refreshPanels(this.panels.length - 1);
              }
              return true;
            },
            'show-event': (event) => {
              this.showEventDetails({
                date,
                event,
                user
              });
            },
            'show-shifts': (shifts) => {
              this.showShiftDetails({
                date,
                shifts,
                user
              });
            }
          }
        };
      } else if (shiftCount > 0) {
        return this.getShiftDetailsPanelData({
          date,
          shifts,
          user
        });
      } else if (eventCount > 0) {
        return this.getEventDetailsPanelData({
          date,
          event: events[0],
          user
        });
      }

      return {
        component: EmptyCell,
        props: {
          date
        },
        events: {}
      };
    },
    openNurseDetails (user) {
      this.nurseDetails = user;
    },
    hideNurseDetails () {
      this.nurseDetails = null;
    },
    updateNurseDetails (user) {
      this.refreshPanels(this.panels.length - 1);
    },
    showNurseDetails (userId) {
      this.showPanel(
        this.getNurseDetailsPanelData,
        [userId],
        true
      );
    },
    getNurseDetailsPanelData (userId) {
      return {
        component: NurseDetails,
        props: {
          date: this.selectedSchedule.startOn,
          user: this.$store.state.org.employees[userId]
        },
        events: {
          'close': () => {
            if (this.panels.length > 1) {
              this.refreshPanels(this.panels.length - 1);
            }
            this.grid.setSelectedUserSettings(null);
            return true;
          },
          'destroyed': () => {
            if (this.grid) {
              this.grid.setSelectedUserSettings(null);
            }
          },
          'notes-saved': () => {
            this.refreshPanels(this.panels.length - 1);
          }
        }
      };
    },
    showShiftDetails (data, resetPanels) {
      this.showPanel(
        this.getShiftDetailsPanelData,
        [data],
        resetPanels
      );
    },
    getShiftDetailsPanelData (data) {
      const { date, selectedShiftIndex, shifts, user } = data;
      const allowDelete = this.scheduleAllowsShiftDeletion;
      const readOnly = this.isDateReadOnly(date);
      return {
        component: ShiftDetails,
        props: {
          'allow-cancel-nurse': !this.scheduleIsDraft,
          'allow-delete': allowDelete,
          date,
          'max-date': this.selectedSchedule.endOn,
          'min-date': this.selectedSchedule.startOn,
          'selected-shift-index': selectedShiftIndex || 0,
          shifts,
          'show-daily-schedule-redirect': this.showDailyScheduleRedirect,
          'read-only': readOnly,
          user
        },
        events: {
          'add-shift': () => {
            this.showPanel(() => {
              return {
                component: ActivitySelection,
                props: {
                  date,
                  scheduleId: this.selectedScheduleId,
                  user
                },
                events: {
                  'close': () => {
                    if (this.panels.length > 1) {
                      this.refreshPanels(this.panels.length - 1);
                    }
                    return true;
                  },
                  'add-event': (event) => {
                    this.addEvent(event.typeId, user.userId, event.dates);
                    this.closeLastPanel();
                  },
                  'add-shift': (shift) => {
                    this.showShiftDetails({
                      date,
                      selectedShiftIndex: shifts.length,
                      shifts: [...shifts, shift],
                      user
                    }, true);
                  }
                }
              };
            }, [], false);
          },
          'canceled-create': (index) => {
            const lastPanel = this.panels.length - 1;
            const params = this.panels[lastPanel].params;
            params[0].shifts.splice(parseInt(index), 1);
            params[0].selectedShiftIndex = 0;
            if (params[0].shifts.length > 0) {
              this.updatePanel(lastPanel, this.panels[lastPanel].panelDataCallback, params);
            } else {
              this.closeLastPanel();
            }
            this.refreshPanels();
          },
          'close': () => {
            if (this.panels.length > 1) {
              this.refreshPanels(this.panels.length - 1);
            }
            return true;
          },
          'created': (shift) => {
            this.$store.commit('scheduling/add_shift', shift);
            const lastPanel = this.panels.length - 1;
            const params = this.panels[lastPanel].params;
            params[0].shifts[params[0].shifts.length - 1] = shift;
            this.updatePanel(lastPanel, this.panels[lastPanel].panelDataCallback, params);
            this.$nextTick(() => {
              this.redrawGrid();
              this.refreshPanels();
              if (!date.isSame(moment(shift.payrollDate || shift.date))) {
                this.grid.selectGridCell(moment(shift.payrollDate || shift.date).valueOf(), this.getCellRowByUser(user.userId), true);
              }
            });
          },
          'has-changes': (hasChanges) => {
            this.hasChanges = hasChanges;
          },
          'removed': (shift) => {
            this.$store.commit('scheduling/remove_shift', shift);
            const lastPanel = this.panels.length - 1;
            const params = this.panels[lastPanel].params;
            const idx = _.findIndex(params[0].shifts, (s) => s.id === shift.id);
            if (idx >= 0) {
              if (params[0].shifts.length > 1) {
                params[0].selectedShiftIndex = 0;
                params[0].shifts = _.cloneDeep(params[0].shifts).splice(idx, 1);
                this.updatePanel(lastPanel, this.panels[lastPanel].panelDataCallback, params);
              } else {
                this.closeLastPanel();
              }
            }
            this.$nextTick(() => {
              this.redrawGrid();
              this.refreshPanels();
              if (!date.isSame(moment(shift.payrollDate || shift.date))) {
                this.grid.selectGridCell(moment(shift.payrollDate || shift.date).valueOf(), this.getCellRowByUser(user.userId), true);
              }
            });
          },
          'updated': (data) => {
            this.$store.commit('scheduling/update_shift', data);
            this.$nextTick(() => {
              this.redrawGrid();
              if (!date.isSame(moment(data.shift.payrollDate || data.shift.date))) {
                this.grid.selectGridCell(moment(data.shift.payrollDate || data.shift.date).valueOf(), this.getCellRowByUser(user.userId), true);
              }
            });
          },
          'saved-splits': ({ createdShifts, updatedShift }) => {
            this.$store.commit('scheduling/update_shift', { originalShift: updatedShift, shift: updatedShift });
            for (let i = 0, len = createdShifts.length; i < len; i++) {
              this.$store.commit('scheduling/add_shift', createdShifts[i]);
            }
            this.$nextTick(function () {
              this.redrawGrid();
              this.refreshPanels();
            });
          }
        }
      };
    },
    showEventDetails (data, resetPanels) {
      this.showPanel(
        this.getEventDetailsPanelData,
        [data],
        resetPanels
      );
    },
    getEventDetailsPanelData (data) {
      const { date, event, user } = data;
      const allowDelete = this.scheduleAllowsShiftDeletion || !!event.id;
      const readOnly = this.isDateReadOnly(date);

      return {
        component: EventDetails,
        props: {
          'allow-delete': allowDelete,
          date,
          event,
          'read-only': readOnly,
          user
        },
        events: {
          'close': () => {
            if (this.panels.length > 1) {
              this.refreshPanels(this.panels.length - 1);
            }
            return true;
          },
          'removed': (event) => {
            this.$store.commit('scheduling/remove_event', {
              event
            });
            this.$nextTick(() => {
              this.redrawGrid();
              this.closeLastPanel();
              this.refreshPanels();
            });
          },
          'updated': (data) => {
            this.$store.commit('scheduling/update_event', {
              ...data
            });
            this.$nextTick(function () {
              this.redrawGrid();
            });
          }
        }
      };
    },
    showDailySummary (date, resetPanels) {
      this.showPanel(this.getDailySummaryPanelData, [date], resetPanels);
    },
    getDailySummaryPanelData (date) {
      let staffNeeded = {};
      if (this.selectedJobTypeTab === this.ALL_JOB_TYPES) {
        if (this.hasSelectedShiftTypes) {
          for (let i = 0, len = this.jobTypes.length; i < len; i++) {
            for (let shiftTypeId of this.selectedShiftTypes) {
              if (this.jobTypes[i].id !== this.ALL_JOB_TYPES &&
                  _.indexOf(this.jobTypes[i].associatedShiftTypes, shiftTypeId) >= 0) {
                _.setWith(
                  staffNeeded,
                  [this.jobTypes[i].id, shiftTypeId],
                  _.get(this.jobTypes[i], ['staffNeeded', shiftTypeId], 0),
                  Object
                );
              }
            }
          }
        } else {
          const jobTypesById = this.$store.getters['scheduling/getJobTypesById'](this.selectedScheduleId);
          for (let jobId in jobTypesById) {
            staffNeeded[jobId] = jobTypesById[jobId].staffNeeded;
          }
        }
      } else {
        const jobTypesById = this.$store.getters['scheduling/getJobTypesById'](this.selectedScheduleId);
        let jobTypeIds = _.keys(jobTypesById);
        if (this.selectedJobTypeTab !== this.ALL_JOB_TYPES) {
          jobTypeIds = this.getSelectedJobTypeIds();
        }
        for (let id of jobTypeIds) {
          let shifts = {};
          if (this.hasSelectedShiftTypes) {
            for (let shiftTypeId of this.selectedShiftTypes) {
              if (_.indexOf(_.get(jobTypesById, [id, 'associatedShiftTypes'], []), shiftTypeId) >= 0) {
                shifts[shiftTypeId] = _.get(jobTypesById, [id, 'staffNeeded', shiftTypeId], 0);
              }
            }
          } else {
            shifts = _.get(jobTypesById, [id, 'staffNeeded'], {});
          }
          staffNeeded[id] = shifts;
        }
      }

      return {
        component: DailySummary,
        props: {
          date,
          scheduleId: this.selectedScheduleId,
          staffNeeded,
          data: this.getDailySummaryShiftsData(date),
          requests: this.getDailySummaryRequestsData(date)
        },
        events: {
          'show-request': this.showRequest,
          'show-shift-details': ({ date, shifts, user }) => {
            this.showShiftDetails({
              date,
              shifts,
              user
            });
          },
          'show-event-details': ({ date, event, user }) => {
            this.showEventDetails({
              date,
              event,
              user
            });
          }
        }
      };
    },
    /**
     * Shows errors overview panel
     */
    showErrorsOverview (validator) {
      if (this.hasChanges) {
        this.$dialog.confirm(getUnsavedChangesDialogProps(this)).then(() => {}).catch(() => {
          this.hasChanges = false;
          this.$store.commit('unmark_all_unsaved_changes');
          this.showPanel(this.getErrorsOverviewPanelData, [this.getValidatorName(validator)], true);
        });
      } else {
        this.showPanel(this.getErrorsOverviewPanelData, [this.getValidatorName(validator)], true);
      }
    },
    getErrorsOverviewPanelData (validatorName) {
      let jobTypeIds = [];
      if (this.selectedJobTypeTab !== this.ALL_JOB_TYPES) {
        jobTypeIds = this.getSelectedJobTypeIds();
      }
      return {
        component: ErrorsOverview,
        props: {
          jobTypeIds,
          scheduleId: this.selectedScheduleId,
          validatorName
        },
        events: {
          'filter': (data) => {
            const job = this.getJobTypeTabIndex(data.job);
            this.selectedJobTypeTab = job >= 0 ? job : this.ALL_JOB_TYPES;
            // Reset staff filter for all actions performed on errors panel
            this.filters = getDefaultFilters();
            if (this.staffFilter) {
              this.staffFilter = '';
            } else {
              this.filterGrid(true);
            }

            if (data.user) {
              this.$nextTick(function () {
                this.grid.focusGridCell(moment(this.selectedSchedule.startOn).valueOf(), this.getCellRowByUser(data.user.userId));
                this.grid.displayCellAtTopLeft(this.FROZEN_COLUMN_COUNT, this.getCellRowByUser(data.user.userId));
              });
            } else {
              this.refreshPanels(1);
            }
          }
        }
      };
    },
    /**
     * Shows requests overview panel
     */
    showRequestsOverview () {
      if (this.hasChanges) {
        this.$dialog.confirm(getUnsavedChangesDialogProps(this)).then(() => {}).catch(() => {
          this.hasChanges = false;
          this.$store.commit('unmark_all_unsaved_changes');
          this.showPanel(this.getRequestsOverviewPanelData, [], true);
        });
      } else {
        this.showPanel(this.getRequestsOverviewPanelData, [], true);
      }
    },
    getRequestsOverviewPanelData () {
      let jobTypeIds = [];
      if (this.selectedJobTypeTab !== this.ALL_JOB_TYPES) {
        jobTypeIds = this.getSelectedJobTypeIds();
      }
      return {
        component: RequestsOverview,
        props: {
          jobTypeIds,
          scheduleId: this.selectedScheduleId
        },
        events: {
          'filter': (data) => {
            const job = this.getJobTypeTabIndex(data.job);
            this.selectedJobTypeTab = job >= 0 ? job : this.ALL_JOB_TYPES;
            this.filters = getDefaultFilters();
            if (this.staffFilter !== data.user) {
              this.staffFilter = data.user;
            } else {
              this.filterGrid(true);
            }
          },
          'show-request': this.showRequest
        }
      };
    },
    renderDownloadGrid () {
      return new Promise((resolve) => {
        if (this.download.grid) {
          this.download.grid.dispose();
          this.download.grid = null;
        }
        let self = this;
        cheetahGrid.themes.default = cheetahGrid.themes.default.extends({
          font: DEFAULT_FONT_SIZE_TABLE + ' ' + DEFAULT_FONT_FAMILY
        });

        const theme = cheetahGrid.themes.SCHEDULER.extends({
          borderColor: '#BDBDBD',
          holidayBgColor: _.get(this.$store.state.org, ['settings', 'scheduling', 'holidays', 'styles', 'web', 'bgColor'], null)
        });

        const validators = this.$store.getters['scheduling/getValidator'](this.selectedScheduleId);
        const getValidationStyle = (shift) => {
          const styles = {};
          for (let name in validators) {
            _.merge(styles, validators[name].style(shift));
          }
          if (shift.overtime) {
            styles.color = scssVariables.error;
          }
          return styles;
        };
        const canceledBgColor = _.get(self.scheduleSymbols, ['shift', 'web', 'canceled', 'bgColor'], null);
        const onCallBgColor = _.get(self.scheduleSymbols, ['shift', 'web', 'onCall', 'bgColor'], null);
        const sitterBgColor = _.get(self.scheduleSymbols, ['shift', 'web', 'sitter', 'bgColor'], null);
        this.$nextTick(() => {
          this.download.grid = new cheetahGrid.ScheduleGrid({
            parentElement: this.$refs.downloadGrid,
            emptyMessage: this.emptyMessage,
            header: this.printHeaders,
            footer: this.printFooters,
            firstDayOfWeek: 0,
            frozenColCount: this.FROZEN_COLUMN_COUNT,
            language: this.gridLanguage,
            showStaffPerDay: true,
            showWeeklyBalance: false,
            symbols: {
              borderColor (cell) {
                const shifts = _.filter(_.get(cell, 'activities', []), (activity) => activity.type === 'shift');
                for (let i = 0, shiftCount = shifts.length; i < shiftCount; i++) {
                  const validationStyle = getValidationStyle(shifts[i]);
                  if (_.has(validationStyle, 'borderColor')) {
                    return validationStyle.borderColor;
                  }
                }
                return null;
              },
              bgColor (cell) {
                const shifts = _.filter(_.get(cell, 'activities', []), (activity) => activity.type === 'shift');
                for (let i = 0, shiftCount = shifts.length; i < shiftCount; i++) {
                  const validationStyle = getValidationStyle(shifts[i]);
                  if (_.has(validationStyle, 'bgColor')) {
                    return validationStyle.bgColor;
                  }
                }
                return null;
              },
              event (value) {
                return self.eventTypes[value.typeId].styles.web;
              },
              shift (shift) {
                if (!self.shiftTypes[shift.typeId]) {
                  return {};
                }
                let style = _.cloneDeep(self.shiftTypes[shift.typeId].styles).web;
                if (shift.available) {
                  style = _.cloneDeep(self.scheduleSymbols.shift.web.available);
                } else {
                  const shiftType = self.shiftTypes[shift.typeId];
                  let startTime = shift.startTime ? shift.startTime : shiftType.startTime;
                  let endTime = shift.endTime ? shift.endTime : shiftType.endTime;
                  if ((startTime !== shiftType.startTime || endTime !== shiftType.endTime) || style.symbolType === 'time') {
                    let start = startTime.substring(0, 5);
                    let end = endTime.substring(0, 5);
                    if (shift.obligatory) {
                      start += '*';
                      end += '  ';
                    }
                    style.symbolType = 'time';
                    style.symbolValue = { start, end };
                  }
                }

                if (shift.departmentId !== self.department.id && self.allDepartments[shift.departmentId]) {
                  const departmentName = self.allDepartments[shift.departmentId].name;
                  style.symbolType = 'text';
                  style.symbolValue = departmentName;
                }

                if (shift.obligatory) {
                  if (_.get(style, 'symbolType', '') === 'text') {
                    style.symbolValue += '*';
                  }
                }

                const validationStyle = getValidationStyle(shift);
                // Validation background color will be applied to the whole cell not individual shifts.
                delete validationStyle.bgColor;
                let bgColor = null;
                if (shift.sitter) {
                  bgColor = sitterBgColor;
                } else if (shift.canceled) {
                  bgColor = canceledBgColor;
                } else if (shift.onCall) {
                  bgColor = onCallBgColor;
                }

                return {
                  ...style,
                  ...validationStyle,
                  bgColor,
                  modified: wasShiftModifiedByManagement(shift, self.$store.state),
                  differentPayrollDate: shift.payrollDate && !moment(shift.payrollDate).isSame(moment(shift.date)),
                  strikeThrough: !isWorkingShiftForDisplay(shift, self.shiftFlags)
                };
              },
              request (value) {
                return _.get(self.$store.state.org, ['settings', 'scheduling', 'symbols', 'request', 'web', 'pendingApprovalOperator'], {
                  color: '#FF5378'
                });
              },
              week (user, week) {
                let style = {};
                if (validators['OvertimeValidator']) {
                  style = validators['OvertimeValidator'].weekStyle(user, week);
                }
                return {
                  bgColor: '#BDBDBD',
                  ...style
                };
              }
            },
            theme
          });

          const filterSource = new cheetahGrid.data.FilterDataSource({
            get (index) {
              return self.paginatedPrintRecords[index] || {};
            },
            length: self.paginatedPrintRecords.length
          });
          filterSource.filter = null;
          this.download.grid.dataSource = filterSource;
          resolve();
        });
      });
    },
    redraw () {
      this.retrievingSchedule = true;
      if (this.grid) {
        this.grid.dispose();
        this.grid = null;
      }
      this.$nextTick(() => {
        this.staffFilter = '';
        this.selectedJobTypeTab = this.ALL_JOB_TYPES;
        const startOn = this.selectedSchedule.startOn;
        // Try to keep the same schedule period when switching departments
        this.retrieveScheduleByDate(startOn).then((schedule) => {
          // Try to keep the same schedule period when switching departments
          let selectedScheduleId = null;
          if (schedule) {
            selectedScheduleId = schedule.id;
            this.selectedScheduleId = schedule.id;
            this.selectedSchedule = schedule;
          } else {
            selectedScheduleId = this.schedulePeriods[0].value;
            this.selectedScheduleId = this.schedulePeriods[0].value;
            this.selectedSchedule = this.schedulePeriods[0];
          }
          this.$store.commit('scheduling/set_active_schedule_id', selectedScheduleId);
          this.closeAllPanels();
          this.retrieveSchedule();
        });
      });
    },
    onShiftUpdateReceived (event) {
      const { action, date, id, assignee_id: assigneeId } = JSON.parse(event.data);
      if (!_.has(this.$store.state.org.employees, [assigneeId]) || !this.selectedSchedule || date < this.selectedSchedule.startOn || date > this.selectedSchedule.endOn) {
        return;
      }
      let activity;
      switch (action) {
        case 'create':
          this.dispatch('scheduling/retrieveShift', id).then(shift => {
            this.$store.commit('scheduling/add_shift', {
              ...shift,
              date: moment(shift.date).toDate(),
              type: 'shift'
            });
            this.$nextTick(() => {
              this.redrawGrid();
            });
          }).catch(() => {});
          break;
        case 'update':
          this.dispatch('scheduling/retrieveShift', id).then(shift => {
            shift.type = 'shift';
            shift.date = moment(shift.date).toDate();
            let originalShift = this.findShiftById(assigneeId, id);
            if (!originalShift) {
              originalShift = _.cloneDeep(shift);
            }

            this.$store.commit('scheduling/update_shift', { originalShift, shift });
            this.$nextTick(() => {
              this.redrawGrid();
            });
          }).catch(() => {});
          break;
        case 'delete':
          activity = this.findShiftById(assigneeId, id);
          if (activity) {
            this.$store.commit('scheduling/remove_shift', activity);
            this.$nextTick(() => {
              this.redrawGrid();
            });
          }
          break;
      }
    },
    onWsUpdate (eventInfo) {
      const data = JSON.parse(eventInfo.data);
      const contentType = data.content_type;
      switch (contentType) {
        case CONTENT_TYPES.shift:
          this.onShiftUpdateReceived(eventInfo);
          break;
      }
    },
    showEmptyPanel (date) {
      this.showPanel(() => {
        return {
          component: EmptyCell,
          props: {
            date
          },
          events: {}
        };
      }, [], true);
    },
    showSelectCellPanel () {
      this.showPanel(() => {
        return {
          component: SelectCell,
          props: {},
          events: {}
        };
      }, [], true);
    },
    showPanel (panelDataCallback, params, resetPanels) {
      if (resetPanels && this.panels.length > 0) {
        this.openedPanelName = 'panel';
        this.closePanels(1, this.panels.length);
        this.updatePanel(0, panelDataCallback, params);
      } else {
        const panelData = this.preparePanelData(panelDataCallback, params);
        panelData.id = _.uniqueId();
        panelData.panelDataCallback = panelDataCallback;
        panelData.params = params;
        this.panels.push(_.cloneDeep(panelData));
      }
    },
    showPersistentPanel (panelDataCallback, params, resetPanels) {
      if (resetPanels && this.persistentPanels.length > 0) {
        this.openedPanelName = 'panel';
        this.closePersistentPanels(1, this.persistentPanels.length);
        this.updatePersistentPanel(0, panelDataCallback, params);
      } else {
        const panelData = this.preparePersistentPanelData(panelDataCallback, params);
        panelData.id = _.uniqueId();
        panelData.panelDataCallback = panelDataCallback;
        panelData.params = params;
        this.persistentPanels.push(_.cloneDeep(panelData));
      }
    },
    showWeeklySummary (week) {
      this.showPanel(this.getWeeklySummaryPanelData, [week], true);
    },
    getWeeklySummaryPanelData (header) {
      const validators = this.$store.getters['scheduling/getValidator'](this.selectedScheduleId);
      const users = {};

      const filter = this.getGridFilter();
      for (let i = 0, len = this.records.length; i < len; i++) {
        const matches = filter ? filter(this.records[i]) : true;
        if (matches) {
          for (let name in validators) {
            const weeklyErrors = validators[name].getWeeklyErrors(header.caption, this.records[i].user);
            if (weeklyErrors) {
              if (!users[this.records[i].user.userId]) {
                users[this.records[i].user.userId] = {
                  employee: { ...this.records[i].user },
                  errors: []
                };
              }
              users[this.records[i].user.userId].errors.push(weeklyErrors);
            }
          }
        }
      }
      return {
        component: WeeklySummary,
        props: {
          header,
          users
        },
        events: {
          'show-user-summary': (user) => {
            this.grid.focusGridCell(header.field, this.getCellRowByUser(user.employee.userId));
          }
        }
      };
    },
    showUserWeeklySummary (user, header) {
      this.showPanel(this.getUserWeeklySummaryPanelData, [user, header], true);
    },
    getUserWeeklySummaryPanelData (user, header) {
      const errors = [];
      const validators = this.$store.getters['scheduling/getValidator'](this.selectedScheduleId);
      for (let name in validators) {
        const weeklyErrors = validators[name].getWeeklyErrors(header.caption, user);
        if (weeklyErrors) {
          errors.push(weeklyErrors);
        }
      }
      return {
        component: UserWeeklySummary,
        props: {
          header,
          errors,
          user
        },
        events: {
          'show-shift': (shift) => {
            const shiftDate = moment(shift.payrollDate || shift.date);
            const startOn = moment(this.selectedSchedule.startOn);
            const endOn = moment(this.selectedSchedule.endOn);
            if (shiftDate.isSameOrAfter(startOn) && shiftDate.isSameOrBefore(endOn)) {
              const shifts = _.filter(
                _.get(this.records, [this.userRecordMap[shift.assigneeId], moment(shift.payrollDate || shift.date).valueOf(), 'activities'], []),
                (a) => {
                  return a.type === 'shift';
                }
              );
              this.showShiftDetails({
                date: moment(shift.payrollDate || shift.date),
                selectedShiftIndex: Math.max(0, _.findIndex(shifts, (s) => s.id === shift.id)),
                shifts,
                user
              });
            }
          }
        }
      };
    },
    updatePanel (panelIdx, panelDataCallback, params) {
      const panel = this.panels[panelIdx];
      const panelData = this.preparePanelData(panelDataCallback, params);
      panelData.id = panel.id;
      panelData.panelDataCallback = panelDataCallback;
      panelData.params = params;
      this.panels.splice(panelIdx, 1, _.cloneDeep(panelData));
    },
    updatePersistentPanel (panelIdx, panelDataCallback, params) {
      const panel = this.persistentPanels[panelIdx];
      const panelData = this.preparePersistentPanelData(panelDataCallback, params);
      panelData.id = panel.id;
      panelData.panelDataCallback = panelDataCallback;
      panelData.params = params;
      this.persistentPanels.splice(panelIdx, 1, _.cloneDeep(panelData));
    },
    refreshPanels (count) {
      if (!this.hasChanges) {
        const len = count || this.panels.length;
        for (let i = 0; i < len; i++) {
          this.updatePanel(i, this.panels[i].panelDataCallback, this.panels[i].params);
        }
      }
    },
    refreshPersistentPanels (count) {
      if (!this.hasChanges) {
        const len = count || this.persistentPanels.length;
        for (let i = 0; i < len; i++) {
          this.updatePersistentPanel(i, this.persistentPanels[i].panelDataCallback, this.persistentPanels[i].params);
        }
      }
    },
    retrieveScheduleByDate (date) {
      return new Promise((resolve, reject) => {
        const schedule = _.find(this.schedulePeriods, (schedule) => date >= schedule.startOn && date <= schedule.endOn);
        if (schedule) {
          resolve(schedule);
        } else {
          this.dispatch('scheduling/retrieveScheduleByDate', {
            departmentId: this.department.id,
            date
          }).then((schedule) => {
            if (schedule) {
              resolve(schedule);
            } else {
              resolve(calculateScheduleRangeForDate(date, this.schedulePeriods, this.$store.state.org.settings));
            }
          }).catch(error => {
            reject(error);
          });
        }
      });
    },
    retrieveScheduleById (scheduleId) {
      return this.dispatch('scheduling/retrieveScheduleById', scheduleId);
    },
    preparePanelData (panelDataCallback, params) {
      const panelData = panelDataCallback(...params);
      const closeCallback = _.get(panelData, ['events', 'close']);
      panelData.events.close = () => {
        if (closeCallback) {
          if (closeCallback()) {
            this.closeLastPanel();
          }
        } else {
          this.closeLastPanel();
        }
      };
      return panelData;
    },
    preparePersistentPanelData (panelDataCallback, params) {
      const panelData = panelDataCallback(...params);
      const closeCallback = _.get(panelData, ['events', 'close']);
      panelData.events.close = () => {
        if (closeCallback) {
          if (closeCallback()) {
            this.closeLastPersistentPanel();
          }
        } else {
          this.closeLastPersistentPanel();
        }
      };
      return panelData;
    },
    closePanels (start, count) {
      this.panels.splice(start, count);
    },
    closePersistentPanels (start, count) {
      this.persistentPanels.splice(start, count);
    },
    closeAllPanels () {
      this.openedPanelName = undefined;
      this.panels.splice(0, this.panels.length);
    },
    closeAllPersistentPanels () {
      this.openedPanelName = undefined;
      this.persistentPanels.splice(0, this.persistentPanels.length);
    },
    closeLastPanel () {
      this.panels.pop();
    },
    closeLastPersistentPanel () {
      this.persistentPanels.pop();
    },
    toggleHelpPanel () {
      this.showHelp = !this.showHelp;
    },
    updateFooterHeight () {
      this.$nextTick(() => {
        const footer = document.getElementsByClassName('schedule-summary-row')[0];
        if (footer) {
          this.footerHeight = footer.getBoundingClientRect().height;
        }
      });
    },
    updateGrid () {
      if (this.grid) {
        this.grid.footer = this.footers;
        this.grid.header = this.headers;
      }
    },
    updateGridHeight () {
      this.updateFooterHeight();
      const grid = document.getElementsByClassName('grid-parent')[0];
      if (grid) {
        const top = grid.getBoundingClientRect().top;
        this.gridTop = top;
        this.gridHeight = window.innerHeight - top;
        this.gridWidth = grid.offsetWidth;
        this.innerHeight = window.innerHeight;
        this.innerWidth = window.innerWidth;
      }
    },
    updateGridEmptyMessage () {
      if (this.grid) {
        this.grid.emptyMessage = this.emptyMessage;
      }
    },
    openFiltersPanel () {
      this.showPersistentPanel(() => {
        this.openedPanelName = 'filters';
        return {
          props: {
            eventFilters: _.cloneDeep(_.get(this.filters, 'event', {})),
            shiftFilters: _.cloneDeep(_.get(this.filters, 'shift', {})),
            userFilters: _.cloneDeep(_.get(this.filters, 'user', {}))
          },
          component: Filters,
          events: {
            close: () => {
              this.openedPanelName = undefined;
              return true;
            },
            apply: (filters) => {
              this.filters = filters;
              this.refreshPersistentPanels();
            }
          }
        };
      }, [], true);
    },
    openOpenShiftsListPanel () {
      this.showPanel(() => {
        this.openedPanelName = 'viewOpenShift';
        let date = this.openShiftDate || moment().startOf('day').format(DATE_FORMAT);
        if (date < this.schedule.startOn || date > this.schedule.endOn) {
          date = this.schedule.startOn;
        }
        this.openShiftDate = date;
        return {
          component: OpenShiftsList,
          props: {
            allowSelectingDate: true,
            date,
            departmentIds: [this.department.id],
            maxDate: this.schedule.endOn,
            minDate: this.schedule.startOn
          },
          events: {
            close: () => {
              this.openedPanelName = undefined;
              return true;
            },
            create: (date) => {
              this.openShiftDate = date;
              this.openCreateOpenShiftPanel();
            },
            'open-details': (openShift) => {
              this.openOpenShiftDetailsPanel(openShift, false);
            }
          }
        };
      }, [], true);
    },
    openCreateOpenShiftPanel () {
      this.showPanel(() => {
        let jobTypeId = null;
        let shiftTypeId = null;
        if (this.selectedJobTypeTab === this.ALL_JOB_TYPES) {
          jobTypeId = this.jobTypes[1].associatedJobTypes[0];
          shiftTypeId = this.jobTypes[1].associatedShiftTypes[0];
        } else {
          jobTypeId = this.groupedJobTypes[this.selectedJobTypeTab][0].associatedJobTypes[0];
          shiftTypeId = this.groupedJobTypes[this.selectedJobTypeTab][0].associatedShiftTypes[0];
        }

        return {
          component: CreateOpenShift,
          props: {
            date: this.openShiftDate,
            jobTypeId,
            shiftTypeId
          },
          events: {
            close: () => {
              this.openOpenShiftsListPanel();
            },
            created: (openShift) => {
              this.openOpenShiftDetailsPanel(openShift, true);
            }
          }
        };
      }, [], false);
    },
    openOpenShiftDetailsPanel (openShift, replaceLastPanel = false) {
      let openShiftCopy = _.cloneDeep(openShift);
      this.openShiftDate = openShiftCopy.date;
      const data = () => {
        return {
          component: OpenShiftDetails,
          props: {
            id: openShiftCopy.id
          },
          events: {
            close: () => {
              this.openOpenShiftsListPanel();
            }
          }
        };
      };
      if (replaceLastPanel) {
        this.updatePanel(this.panels.length - 1, data, []);
      } else {
        this.showPanel(data, [], false);
      }
    },
    setOpenedPanelName (name) {
      if (this.hasChanges) {
        this.$dialog.confirm(getUnsavedChangesDialogProps(this)).then(() => {}).catch(() => {
          this.hasChanges = false;
          this.$store.commit('unmark_all_unsaved_changes');
          this.openedPanelName = name;
        });
      } else {
        this.openedPanelName = name;
      }
    },
    isSelectedCellInViewAfterFilter (criteria) {
      if (this.selectedCell.col) {
        if (this.selectedCell.user) {
          const filters = [];
          const selectedJobTypeTab = criteria['selectedJobTypeTab'] || this.selectedJobTypeTab;
          if (selectedJobTypeTab !== this.ALL_JOB_TYPES) {
            const associatedJobTypes = this.groupedJobTypes[selectedJobTypeTab].map((jt) => jt.associatedJobTypes).reduce((accumulator, currentValue) => {
              accumulator.push(...currentValue);
              return accumulator;
            }, []);
            filters.push((record) => {
              return _.indexOf(associatedJobTypes, record.user.jobTypeId) >= 0;
            });
          }

          const staffFilter = criteria['staffFilter'] || this.staffFilter;
          if (staffFilter) {
            const text = staffFilter.toLowerCase();
            filters.push((record) => {
              return userMatchesText(record.user, text);
            });
          }

          const filtersCount = filters.length;
          let filter = null;
          if (filtersCount > 0) {
            filter = (record) => {
              let match = true;
              for (let i = 0; i < filtersCount; i++) {
                if (_.isFunction(filters[i])) {
                  match &= filters[i](record);
                } else {
                  match &= _.get(record, filters[i].key, null) === filters[i].value;
                }
              }
              return match;
            };
          }
          for (let i = 0, len = this.records.length; i < len; i++) {
            if (filter ? filter(this.records[i]) : true) {
              if (this.records[i].user.userId === this.selectedCell.user) {
                return true;
              }
            }
          }
        }
      }
      return false;
    }
  }
};
</script>

<style lang="scss">
.container.schedule {
  height: 100%;

  .cell-details {
    transition: width 0.3s;
  }

  .v-tabs {
    .v-tabs-bar {
      border-color: #eeeeee;
      border-style: solid;
      border-width: 1px 1px 0 1px;
      margin-right: 10px;
    }
  }

  .grid-action-bar {
    background-color: white;
    border-color: map-get($grey, 'lighten-3');
    border-style: solid;
    border-width: 1px 1px 0 1px;
    margin-left: 0px;
    margin-right: 10px;
    min-height: 60px;

    .schedule-dropdown {
      border: 1px solid map-get($grey, 'lighten-2');
      .v-btn__content {
        justify-content: left;
        i {
          position: absolute;
          right: 8px;
        }
      }
    }
  }

  .grid-empty-container {
    text-align: left;
  }

  .search-staff {
    position: absolute;
    z-index: 1;

    .v-icon {
      margin-top: 0px !important;
    }
  }
  .shift-type {
    padding: 0px 5px !important;
    min-width: 34px !important;
    width: 34px !important;
  }
}

$summary-color: #FDE3E3;

.schedule-summary-row {
  background-color: $summary-color !important;
  .v-btn {
    background-color: rgba(255,255,255,0.4) !important;
  }
}
.schedule-summary-row-toggle {
  background-color: $summary-color !important;
  bottom: 54px;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  will-change: bottom;
  -webkit-transition-property: bottom;
  transition-property: bottom;

  &.active {
    bottom: 108px;
    -webkit-transition-duration: 0.05s;
    transition-duration: 0.05s;
  }
}
</style>
