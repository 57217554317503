<template>
  <v-container
    class="px-8 py-1"
  >
    <v-list-item class="headline page-title pa-0">
      <v-list-item-icon class="icon">
        <v-icon>fal fa-plus</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="panel-title">
        {{ $t('labels.createOpenShift') }}
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-row>
      <v-col
        class="secondary--text body-2"
        cols="12"
      >
        <v-menu
          v-if="allowEditingDate"
          ref="datePicker"
          v-model="showDatePicker"
          :close-on-content-click="false"
          offset-y
          :nudge-bottom="0"
          max-width="376px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <fieldset
              class="nb-fieldset mt-n3"
              v-bind="attrs"
              v-on="on"
            >
              <legend class="grey--text text--darken-2 caption-2">
                {{ $tc('labels.date', 1) }}
              </legend>
              <div class="body-2 pb-2 text-truncate grey--text text--darken-3">
                {{ moment(model.date).format(dateFormatStringWithDoW) }}
              </div>
            </fieldset>
          </template>
          <v-date-picker
            no-title
            :value="moment(model.date).format('YYYY-MM-DD')"
            width="376px"
            @input="changeDate"
          />
        </v-menu>
        <template v-else>
          {{ moment(date).format(dateFormatStringWithDoW) }}
        </template>
      </v-col>
    </v-row>
    <v-container
      id="createOpenShiftForm"
      class="pa-0"
    >
      <ValidationObserver
        ref="observer"
        v-slot="{ invalid, passes }"
      >
        <v-container
          class="pa-0"
          :style="containerStyle"
        >
          <v-card
            class="pa-4"
            outlined
          >
            <v-alert
              v-if="payrollDate"
              class="caption dense font-weight-medium mx-0"
              color="info"
              dense
              outlined
              text
            >
              <v-icon
                slot="prepend"
                class="ml-n1 mr-3"
                color="info"
                size="12"
              >
                fas fa-info-circle
              </v-icon>
              {{ $t('descriptions.differentPayrollDate', { date: payrollDate }) }}
            </v-alert>
            <v-row
              :class="[allowEditingDate ? 'edit-dept-allowed' : '']"
              no-gutters
            >
              <v-col
                class="pr-4"
                cols="7"
              >
                <v-select
                  v-if="allowEditingDate"
                  ref="selectDepartment"
                  v-model="model.departmentId"
                  dense
                  hide-details
                  item-text="name"
                  item-value="id"
                  :items="departments"
                  :label="$tc('labels.department', 1)"
                  outlined
                />
                <fieldset
                  v-else
                  class="nb-fieldset"
                >
                  <legend class="grey--text">
                    {{ $tc('labels.department', 1) }}
                  </legend>
                  <div class="body-2 pb-2 text-truncate grey--text">
                    {{ targetDepartment.name }}
                  </div>
                </fieldset>
              </v-col>
              <v-col cols="4">
                <v-switch
                  v-model="model.sitter"
                  class="sitter-toggle"
                  color="success"
                  dense
                  inset
                  hide-details
                >
                  <template v-slot:label>
                    <span
                      class="mr-2 body-2 grey--text text--darken-3"
                      :title="$t('labels.sitter')"
                    >
                      {{ $t('labels.sitter') }}
                    </span>
                  </template>
                </v-switch>
              </v-col>
              <v-col cols="1">
                <v-menu
                  ref="payrollDatePicker"
                  v-model="openPayrollPicker"
                  :close-on-content-click="false"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-tooltip
                      max-width="300px"
                      top
                    >
                      <template #activator="{ on: tooltipOn, attrs: tooltipAttr }">
                        <v-btn
                          :class="[openPayrollPicker ? 'primary white--text' : 'grey lighten-3', 'payroll-picker']"
                          icon
                          small
                          v-bind="{...tooltipAttr, ...attrs}"
                          @click="openPayrollPicker = !openPayrollPicker"
                          v-on="{...tooltipOn, ...on}"
                        >
                          <span class="payroll-date-icon">
                            <i class="fal fa-calendar" />
                            <i class="fas fa-dollar-sign" />
                          </span>
                        </v-btn>
                      </template>
                      <span class="body-2">
                        {{ $t('labels.payrollDate') }}
                      </span>
                    </v-tooltip>
                  </template>
                  <v-container
                    class="primary white--text"
                  >
                    {{ $t('labels.selectPayrollDate') }}
                  </v-container>
                  <v-date-picker
                    v-model="model.payrollDate"
                    class="payroll-picker"
                    no-title
                    @change="openPayrollPicker = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                class="pr-4"
                cols="9"
              >
                <VeeSelect
                  v-model="model.jobTypes"
                  dense
                  hide-details
                  item-text="name"
                  item-value="id"
                  :items="jobTypes"
                  :label="$tc('labels.eligibleJobType', 2)"
                  :menu-props="{ top: false, offsetY: true }"
                  multiple
                  name="jobTypes"
                  outlined
                  rules="required"
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @mousedown.prevent
                      @click="toggleAllJobTypes"
                    >
                      <v-list-item-action>
                        <v-icon
                          color="secondary"
                          small
                        >
                          {{ jobTypesSelectionIcon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t('labels.selectAll') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-0" />
                  </template>
                  <template #item="{ item }">
                    <v-list-item-action>
                      <v-icon
                        color="secondary"
                        small
                      >
                        {{ model.jobTypes.includes(item.id) ? 'far fa-check-square' : 'far fa-square' }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </VeeSelect>
              </v-col>
              <v-col cols="3">
                <VeeTextField
                  v-model.number="model.opening"
                  dense
                  :disabled="model.sitter"
                  hide-details
                  :label="$t('labels.need')"
                  name="opening"
                  outlined
                  rules="required|numeric|min_value:1"
                />
              </v-col>
            </v-row>
            <v-row
              v-if="model.sitter"
              align="center"
              class="grey--text text--darken-3 "
              justify="center"
              no-gutters
            >
              <v-col
                class="pr-2"
                cols="6"
              >
                <v-text-field
                  v-model="model.settings.sitter.room"
                  class="sitter-details"
                  clearable
                  dense
                  hide-details
                  :label="$t('labels.room')"
                  outlined
                />
              </v-col>
              <v-spacer />
              <v-col
                class="pl-2"
                cols="6"
              >
                <v-text-field
                  v-model="model.settings.sitter.reason"
                  class="sitter-details"
                  clearable
                  dense
                  hide-details
                  :label="$t('labels.reason')"
                  outlined
                />
              </v-col>
            </v-row>
            <v-row
              no-gutters
            >
              <v-col
                class="pr-4"
                cols="5"
              >
                <VeeSelect
                  ref="selectShiftType"
                  v-model="model.typeId"
                  class="shift-select"
                  dense
                  hide-details
                  item-text="name"
                  item-value="id"
                  :items="shiftTypes"
                  :label="$t('labels.shift')"
                  :menu-props="{ top: false, offsetY: true }"
                  outlined
                  rules="required"
                >
                  <template #item="{ item }">
                    <span class="mt-n1">
                      <span class="body-2">{{ item.name }}</span>
                      <span class="body-2 ml-2">{{ formatTimeRange(item) }}</span>
                    </span>
                  </template>
                  <template #selection="{ item }">
                    <span class="mt-n1">
                      <span class="body-2">{{ item.name }}</span>
                    </span>
                  </template>
                </VeeSelect>
              </v-col>
              <v-col cols="3">
                <VeeSelect
                  ref="selectShiftStart"
                  v-model="model.startTime"
                  class="d-inline-block"
                  dense
                  hide-details
                  item-text="name"
                  item-value="id"
                  :items="startTimes"
                  :label="$t('labels.start')"
                  menu-props="auto"
                  outlined
                  rules="required"
                />
              </v-col>
              <v-col
                class="shift-separator"
                cols="1"
              >
                -
              </v-col>
              <v-col cols="3">
                <VeeSelect
                  ref="selectShiftEnd"
                  v-model="model.endTime"
                  class="d-inline-block"
                  dense
                  hide-details
                  item-text="name"
                  item-value="id"
                  :items="endTimes"
                  :label="$t('labels.end')"
                  menu-props="auto"
                  outlined
                  rules="required"
                />
              </v-col>
            </v-row>
            <v-row
              no-gutters
            >
              <v-col
                class="pr-4"
                cols="7"
              >
                <VeeSelect
                  ref="selectBiddingEndtime"
                  v-model="hoursBeforeBiddingEnds"
                  class="bidding-ends-select"
                  dense
                  hide-details
                  item-text="name"
                  item-value="id"
                  :items="hoursBeforeBiddingEndsList"
                  :label="$t('labels.biddingEnds')"
                  :menu-props="{ top: false, offsetY: true }"
                  outlined
                  rules="required"
                />
              </v-col>
              <v-col cols="5">
                <span class="body-2 d-inline-block pt-2">
                  {{ $t('labels.hoursBefore', {hours: formatTime(model.startTime)}) }}
                </span>
              </v-col>
            </v-row>
            <v-row
              no-gutters
            >
              <v-col cols="12">
                <FlagSelection
                  ref="selectShiftFlag"
                  v-model="model.flags"
                  small-chips
                  class="shift-flags"
                  dense
                  :filter="flagsFilter"
                  hide-details
                  item-text="shortCode"
                  item-value="id"
                  :items="shiftFlags"
                  :label="$tc('labels.flag', 2)"
                  multiple
                  outlined
                  :return-object="false"
                />
              </v-col>
            </v-row>
            <v-row
              no-gutters
            >
              <v-col cols="12">
                <Comments
                  v-model="model.comments"
                  :auto-grow="true"
                  class="body-2"
                  counter="1000"
                  :disclosure-hint="$t('descriptions.disclaimer')"
                  maxlength="1000"
                  outlined
                  :placeholder="$t('labels.addCommentsPlaceholder')"
                  rows="1"
                  single-line
                  :visibility-hint="$t('descriptions.commentVisibilityAll')"
                />
              </v-col>
            </v-row>
            <v-row
              class="mb-0"
              no-gutters
            >
              <v-col cols="12">
                <v-radio-group
                  v-model="model.fcfs"
                  class="mt-0 pt-0"
                  dense
                  hide-details
                  mandatory
                >
                  <v-radio
                    class="mb-2"
                    color="secondary"
                    :label="$t('labels.firstComeFirstServed')"
                    :value="true"
                  />
                  <v-radio
                    color="secondary"
                    :label="$t('labels.manuallySelectBidders')"
                    :value="false"
                  />
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            class="pa-4 mt-4"
            outlined
          >
            <v-row no-gutters>
              <v-col
                class="grey--text caption"
                cols="12"
              >
                {{ $t('descriptions.openShiftNotification') }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <VeeSelect
                  v-model="model.eligibleDepartments"
                  dense
                  hide-details
                  item-text="name"
                  item-value="id"
                  :items="departments"
                  :label="$tc('labels.department', 1)"
                  multiple
                  name="departments"
                  outlined
                  rules="required"
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @mousedown.prevent
                      @click="toggleAllDepartments"
                    >
                      <v-list-item-action>
                        <v-icon
                          color="secondary"
                          small
                        >
                          {{ departmentSelectionIcon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t('labels.selectAll') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2" />
                  </template>
                  <template #item="{ item }">
                    <v-list-item-action>
                      <v-icon
                        color="secondary"
                        small
                      >
                        {{ model.eligibleDepartments.includes(item.id) ? 'far fa-check-square' : 'far fa-square' }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </VeeSelect>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <VeeSelect
                  v-model="model.eligibleJobStatus"
                  dense
                  hide-details
                  item-text="name"
                  item-value="id"
                  :items="jobStatus"
                  :label="$t('labels.jobStatus')"
                  multiple
                  name="jobStatus"
                  outlined
                  rules="required"
                >
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @mousedown.prevent
                      @click="toggleAllJobStatus"
                    >
                      <v-list-item-action>
                        <v-icon
                          color="secondary"
                          small
                        >
                          {{ jobStatusSelectionIcon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ $t('labels.selectAll') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-0" />
                  </template>
                  <template #item="{ item }">
                    <v-list-item-action>
                      <v-icon
                        color="secondary"
                        small
                      >
                        {{ model.eligibleJobStatus.includes(item.id) ? 'far fa-check-square' : 'far fa-square' }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </VeeSelect>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
        <v-row
          v-if="$can('edit', 'openShift')"
          class="create-open-shift"
          no-gutters
        >
          <v-col>
            <v-btn
              class="px-4 float-right create"
              color="accent"
              :disabled="saving || invalid"
              @click="passes(create)"
            >
              <v-progress-circular
                v-if="saving"
                class="px-7"
                color="secondary"
                indeterminate
                size="22"
                width="2"
              />
              <span v-else>
                {{ $t('labels.create') }}
              </span>
            </v-btn>
            <v-btn
              class="px-4 mr-4 float-right"
              color="primary"
              :disabled="saving"
              outlined
              @click="clear"
            >
              {{ $t('labels.clear') }}
            </v-btn>
          </v-col>
        </v-row>
      </ValidationObserver>
    </v-container>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import Comments from '@/components/Comments';
import VeeSelect from '@/components/form_controls/VeeSelect';
import VeeTextField from '@/components/form_controls/VeeTextField';
import { showStatus } from '@/plugins/vue-notification';
import { DATE_FORMAT, DATETIME_FORMAT } from '@/utils/ui';
import { SHIFT_TIME_INTERVAL } from '@/views/scheduling/constants';
import FlagSelection from '@/components/scheduling/FlagSelection';

export default {
  components: {
    Comments,
    FlagSelection,
    VeeSelect,
    VeeTextField
  },
  props: {
    allowEditingDate: {
      default: false,
      type: Boolean
    },
    allowEditingDepartment: {
      default: false,
      type: Boolean
    },
    date: {
      default: function () {
        return moment().format(DATE_FORMAT);
      },
      type: String
    },
    jobTypeId: {
      default: null,
      type: Number
    },
    shiftTypeId: {
      default: null,
      type: Number
    }
  },
  data () {
    return {
      hoursBeforeBiddingEnds: 0.5,
      model: this.getInitialModel(),
      contentHeight: 500,
      openPayrollPicker: false,
      saving: false,
      showDatePicker: false
    };
  },
  computed: {
    allDepartmentsSelected () {
      return this.model.eligibleDepartments.length === this.departments.length;
    },
    allJobStatusSelected () {
      return this.model.eligibleJobStatus.length === this.jobStatus.length;
    },
    allJobTypesSelected () {
      return this.model.jobTypes.length === this.jobTypes.length;
    },
    containerStyle () {
      return {
        'height': `${this.contentHeight - 190}px`, // Subtract padding for the header and footer
        'overflow-y': 'auto'
      };
    },
    dateFormatStringWithDoW () {
      return this.$store.getters['org/getDateFormatLongWithDoW']();
    },
    departments () {
      return _.filter(this.$store.state.org.departments, (department) => department.partakeInScheduling);
    },
    departmentSelectionIcon () {
      if (this.allDepartmentsSelected) {
        return 'far fa-check-square';
      } else if (this.someDepartmentsSelected) {
        return 'far fa-minus-square';
      }

      return 'far fa-square';
    },
    endTimes () {
      const times = this.getTimes();
      const index = _.findIndex(times, (t) => t.id === this.model.startTime);
      if (index >= 0) {
        times[index].disabled = true;
      }
      return times;
    },
    hoursBeforeBiddingEndsList () {
      const hours = [];
      for (let i = 0.5; i <= 24; i = i + 0.5) {
        hours.push({
          id: i,
          name: i
        });
      }
      return hours;
    },
    jobStatus () {
      return _.sortBy(this.$store.state.org.jobStatus, ['name']);
    },
    jobStatusSelectionIcon () {
      if (this.allJobStatusSelected) {
        return 'far fa-check-square';
      } else if (this.someJobStatusSelected) {
        return 'far fa-minus-square';
      }

      return 'far fa-square';
    },
    jobTypesSelectionIcon () {
      if (this.allJobTypesSelected) {
        return 'far fa-check-square';
      } else if (this.someJobTypesSelected) {
        return 'far fa-minus-square';
      }

      return 'far fa-square';
    },
    jobTypes () {
      return _.sortBy(this.$store.state.org.jobTypes.filter(job => job.partakeInScheduling), ['name']);
    },
    payrollDate () {
      let payrollDate = '';
      if (this.model.payrollDate && !moment(this.model.payrollDate).isSame(moment(this.model.date))) {
        payrollDate = moment(this.model.payrollDate).format(this.$store.getters['org/getDateFormatLong']());
      }

      return payrollDate;
    },
    shiftFlags () {
      return _.sortBy(this.$store.state.org.flags, ['name']);
    },
    shiftFlagsById () {
      return this.shiftFlags.reduce((flags, value) => {
        flags[value.id] = value;
        return flags;
      }, {});
    },
    shiftTypes () {
      return _.sortBy(this.$store.state.org.shiftTypes.filter(shift => shift.partakeInScheduling), ['name']);
    },
    someDepartmentsSelected () {
      return this.model.eligibleDepartments.length > 0 && !this.allDepartmentsSelected;
    },
    someJobStatusSelected () {
      return this.model.eligibleJobStatus.length > 0 && !this.allJobStatusSelected;
    },
    someJobTypesSelected () {
      return this.model.jobTypes.length > 0 && !this.allJobTypesSelected;
    },
    startTimes () {
      const times = this.getTimes();
      const index = _.findIndex(times, (t) => t.id === this.model.endTime);
      if (index >= 0) {
        times[index].disabled = true;
      }
      return times;
    },
    targetDepartment () {
      return this.$store.getters['org/getDepartmentById'](this.model.departmentId);
    },
    today () {
      return moment().format(DATE_FORMAT);
    }
  },
  watch: {
    'model.typeId' () {
      const shiftType = this.$store.getters['org/getShiftTypeById'](this.model.typeId);
      this.model.startTime = shiftType.startTime;
      this.model.endTime = shiftType.endTime;
      this.model.onCall = shiftType.onCall;
    }
  },
  mounted: function () {
    this.updateContentHeight();
    window.addEventListener('resize', _.debounce(this.updateContentHeight, 500));
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.updateContentHeight);
  },
  methods: {
    changeDate (date) {
      this.model.date = date;
      this.model.payrollDate = date;
      this.showDatePicker = false;
    },
    clear () {
      const initialModel = this.getInitialModel();
      for (let key in initialModel) {
        this.model[key] = initialModel[key];
      }
      this.hoursBeforeBiddingEnds = 0.5;
    },
    create () {
      this.saving = true;
      const payload = _.cloneDeep(this.model);
      payload.flags = _.filter(payload.flags, _.isFinite);

      let shiftStartDate = moment(`${payload.date} ${payload.startTime}`);
      shiftStartDate.subtract(this.hoursBeforeBiddingEnds, 'h').subtract(2, 'm');
      payload.biddingEndsOn = shiftStartDate.utc().format(DATETIME_FORMAT);
      this.dispatch('scheduling/createOpenShift', payload).then((openShift) => {
        showStatus({
          text: this.$t('descriptions.openShiftCreationSuccess')
        });
        this.$emit('created', openShift);
      }).catch(error => {
        const responseData = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.openShiftCreationFail'),
          type: 'error',
          responseData
        });
      }).finally(() => {
        this.saving = false;
      });
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    flagsFilter (item, queryText) {
      return item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.shortCode.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    },
    formatDate (date) {
      return moment(date).format('YYYY/MM/DD');
    },
    formatTime (time) {
      return _.split(time, ':', 2).join(':');
    },
    formatTimeRange (shift) {
      return '(' + [
        this.formatTime(shift.startTime),
        this.formatTime(shift.endTime)
      ].join(' - ') + ')';
    },
    getInitialModel () {
      const shiftTypes = _.sortBy(this.$store.state.org.shiftTypes.filter(shift => shift.partakeInScheduling), ['name']);
      const jobTypes = _.sortBy(this.$store.state.org.jobTypes.filter(job => job.partakeInScheduling), ['name']);

      let selectedShiftType = shiftTypes[0];
      let selectedJobType = jobTypes[0].id;
      if (this.jobTypeId) {
        const preferedJobType = _.find(jobTypes, (j) => j.id === this.jobTypeId);
        if (preferedJobType) {
          selectedJobType = preferedJobType.id;
        }
      }
      if (this.shiftTypeId) {
        const preferedShiftType = _.find(shiftTypes, (s) => s.id === this.shiftTypeId);
        if (preferedShiftType) {
          selectedShiftType = preferedShiftType;
        }
      }

      const activeDepartment = this.$store.getters['org/getActiveDepartment']();
      return {
        comments: '',
        date: this.date,
        departmentId: activeDepartment.id,
        eligibleDepartments: [activeDepartment.id],
        eligibleJobStatus: [],
        endTime: selectedShiftType.endTime,
        fcfs: false,
        flags: [],
        jobTypes: [selectedJobType],
        opening: 1,
        onCall: selectedShiftType.onCall,
        payrollDate: this.date,
        settings: {
          sitter: {
            reason: '',
            room: ''
          }
        },
        sitter: false,
        startTime: selectedShiftType.startTime,
        typeId: selectedShiftType.id
      };
    },
    getTimes () {
      const times = [];
      let date = moment('00:00:00', 'HH:mm:ss');
      let end = moment('23:30:00', 'HH:mm:ss');
      while (date.isSameOrBefore(end)) {
        times.push({
          id: date.format('HH:mm:ss'),
          name: date.format('HH:mm')
        });
        date.add(SHIFT_TIME_INTERVAL, 'minutes');
      }
      return times;
    },
    moment,
    toggleAllDepartments () {
      if (this.allDepartmentsSelected) {
        this.model.eligibleDepartments = [];
      } else {
        this.model.eligibleDepartments = this.departments.map((d) => d.id);
      }
    },
    toggleAllJobStatus () {
      if (this.allJobStatusSelected) {
        this.model.eligibleJobStatus = [];
      } else {
        this.model.eligibleJobStatus = this.jobStatus.map((d) => d.id);
      }
    },
    toggleAllJobTypes () {
      if (this.allJobTypesSelected) {
        this.model.jobTypes = [];
      } else {
        this.model.jobTypes = this.jobTypes.map((d) => d.id);
      }
    },
    updateContentHeight () {
      const el = document.getElementsByClassName('side-panel')[0];
      if (el) {
        this.contentHeight = el.clientHeight;
      }
    }
  }
};
</script>

<style lang="scss">
#createOpenShiftForm {
  .edit-dept-allowed {
    .sitter-toggle, .payroll-picker {
      margin-top: 6px;
    }
  }
  .date-chip .v-chip__content {
    text-shadow: none !important;
  }
  .create-open-shift {
    bottom: 32px;
    margin-bottom: 0px !important;
    position: absolute;
    right: 32px;
  }
  .notify-error {
    border: 2px solid $error !important;
  }
  .payroll-picker {
    margin-top: 12px;
    .payroll-date-icon {
      .fa-calendar {
        font-size: 16px;
        margin-left: -1px;
      }
      .fa-dollar-sign {
        position: absolute;
        left: 10px;
        font-size: 9px;
        top: 6px;
      }
    }
  }
  .row {
    &.no-gutters {
      margin-bottom: 16px;
    }
  }
  .shift-select {
    .v-select__selections {
      span {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
      input{
        display: none;
      }
    }
  }
  .shift-separator {
    margin-top: 8px;
    text-align: center;
  }
  .sitter-toggle {
    padding-left: 10px;
  }
  .v-select {
    .v-input__slot {
      padding-right: 4px;
    }
  }
  .v-input--radio-group {
    label {
      font-size: 14px;
    }
  }
  .v-input--switch {
    margin-top: 12px;
    .v-input--selection-controls__input {
      margin-right: 0px;
    }
    .v-messages {
      margin-left: 48px;
      .v-messages__message {
        color: map-get($grey, 'base');
        font-size: 12px !important;
      }
    }
  }
  .v-select--chips {
    .v-select__selections {
      margin-top: 4px !important;
    }
  }
}
</style>
