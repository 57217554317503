var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-8 py-0 schedule-filters" },
    [
      _c(
        "v-list-item",
        { staticClass: "headline page-title pa-0" },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "icon" },
            [_c("v-icon", [_vm._v("fal fa-filter")])],
            1
          ),
          _c("v-list-item-content", { staticClass: "panel-title" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("labels.filter")) + "\n    ")
          ]),
          _c(
            "v-list-item-action",
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("fal fa-times")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "pa-0", style: _vm.containerStyle },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-select", {
                    staticClass: "mb-1 inline user-status",
                    attrs: {
                      dense: "",
                      "hide-details": "",
                      "item-text": "name",
                      "item-value": "id",
                      items: _vm.jobStatus,
                      label:
                        _vm.filters.user.status.length === 0
                          ? _vm.$t("labels.allJobStatus")
                          : _vm.$t("labels.jobStatus"),
                      "menu-props": { top: false, offsetY: true },
                      multiple: "",
                      name: "jobStatus",
                      outlined: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prepend-item",
                        fn: function() {
                          return [
                            _c(
                              "v-list-item",
                              {
                                attrs: { ripple: "" },
                                on: {
                                  mousedown: function($event) {
                                    $event.preventDefault()
                                  },
                                  click: function($event) {
                                    _vm.filters.user.status = []
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-list-item-action",
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: { color: "secondary", small: "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.filters.user.status.length ===
                                                0
                                                ? "far fa-check-square"
                                                : "far fa-square"
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("labels.allJobStatus")
                                          ) +
                                          "\n                "
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("v-divider")
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "selection",
                        fn: function(ref) {
                          var index = ref.index
                          var item = ref.item
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  item.name +
                                    (index ===
                                    _vm.filters.user.status.length - 1
                                      ? ""
                                      : ",")
                                ) +
                                "\n          "
                            )
                          ]
                        }
                      },
                      {
                        key: "item",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-list-item-action",
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { color: "secondary", small: "" } },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.filters.user.status.includes(
                                            item.id
                                          )
                                            ? "far fa-check-square"
                                            : "far fa-square"
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.name) +
                                      "\n              "
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.filters.user.status,
                      callback: function($$v) {
                        _vm.$set(_vm.filters.user, "status", $$v)
                      },
                      expression: "filters.user.status"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-select", {
                    staticClass: "mb-1 inline primary-shift-types",
                    attrs: {
                      dense: "",
                      hint: _vm.$t("descriptions.allPrimaryShiftTypes"),
                      "item-text": "name",
                      "item-value": "id",
                      items: _vm.shiftTypes,
                      label:
                        _vm.filters.user.shiftTypes.length === 0
                          ? _vm.$t("labels.allPrimaryShiftTypes")
                          : _vm.$t("labels.primaryShiftTypes"),
                      "menu-props": { top: false, offsetY: true },
                      multiple: "",
                      outlined: "",
                      "persistent-hint": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "prepend-item",
                        fn: function() {
                          return [
                            _c(
                              "v-list-item",
                              {
                                attrs: { ripple: "" },
                                on: {
                                  mousedown: function($event) {
                                    $event.preventDefault()
                                  },
                                  click: function($event) {
                                    _vm.filters.user.shiftTypes = []
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-list-item-action",
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: { color: "secondary", small: "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.filters.user.shiftTypes
                                                .length === 0
                                                ? "far fa-check-square"
                                                : "far fa-square"
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "labels.allPrimaryShiftTypes"
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("v-divider")
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "selection",
                        fn: function(ref) {
                          var index = ref.index
                          var item = ref.item
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  item.name +
                                    (index ===
                                    _vm.filters.user.shiftTypes.length - 1
                                      ? ""
                                      : ",")
                                ) +
                                "\n          "
                            )
                          ]
                        }
                      },
                      {
                        key: "item",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-list-item-action",
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { color: "secondary", small: "" } },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.filters.user.shiftTypes.includes(
                                            item.id
                                          )
                                            ? "far fa-check-square"
                                            : "far fa-square"
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.name) +
                                      "\n              "
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.filters.user.shiftTypes,
                      callback: function($$v) {
                        _vm.$set(_vm.filters.user, "shiftTypes", $$v)
                      },
                      expression: "filters.user.shiftTypes"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            { staticClass: "pa-4 mb-4", attrs: { outlined: "" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-switch", {
                        staticClass:
                          "pt-0 mt-0 ml-1 d-inline-block shift-enabled",
                        attrs: {
                          color: "success",
                          dense: "",
                          "hide-details": "",
                          inset: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "mr-4 body-2 grey--text text--darken-3",
                                    attrs: { title: _vm.$t("labels.shift") }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("labels.shift")) +
                                        "\n              "
                                    )
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.filters.shift.enabled,
                          callback: function($$v) {
                            _vm.$set(_vm.filters.shift, "enabled", $$v)
                          },
                          expression: "filters.shift.enabled"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-combobox", {
                        ref: "selectShiftFlag",
                        staticClass: "shift-flags",
                        attrs: {
                          "small-chips": "",
                          dense: "",
                          filter: _vm.flagsFilter,
                          "hide-details": "",
                          "item-text": "shortCode",
                          "item-value": "id",
                          items: _vm.shiftFlags,
                          label:
                            _vm.filters.shift.flags.length === 0
                              ? _vm.$t("labels.allFlags")
                              : _vm.$tc("labels.flag", 2),
                          multiple: "",
                          outlined: "",
                          "return-object": false
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend-item",
                            fn: function() {
                              return [
                                _c(
                                  "v-list-item",
                                  {
                                    attrs: { ripple: "" },
                                    on: {
                                      mousedown: function($event) {
                                        $event.preventDefault()
                                      },
                                      click: function($event) {
                                        _vm.filters.shift.flags = []
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list-item-action",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              color: "secondary",
                                              small: ""
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.filters.shift.flags
                                                    .length === 0
                                                    ? "far fa-check-square"
                                                    : "far fa-square"
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t("labels.allFlags")
                                              ) +
                                              "\n                  "
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("v-divider")
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "selection",
                            fn: function(ref) {
                              var flagId = ref.item
                              return [
                                _vm.shiftFlagsById[flagId]
                                  ? _c(
                                      "v-chip",
                                      {
                                        staticClass:
                                          "lighten-2 grey--text text--darken-3 flag-short-code mb-1",
                                        attrs: {
                                          color: "info",
                                          small: "",
                                          title: _vm.shiftFlagsById[flagId].name
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.shiftFlagsById[flagId]
                                                .shortCode
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            }
                          },
                          {
                            key: "item",
                            fn: function(ref) {
                              var attrs = ref.attrs
                              var flag = ref.item
                              var on = ref.on
                              return [
                                _c(
                                  "v-list-item",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        class: [
                                          _vm.filters.shift.flags.includes(
                                            flag.id
                                          )
                                            ? "v-list-item--active"
                                            : "",
                                          "flag-item"
                                        ],
                                        attrs: { title: flag.name }
                                      },
                                      "v-list-item",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-list-item-action",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              color: "secondary",
                                              small: ""
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.filters.shift.flags.includes(
                                                    flag.id
                                                  )
                                                    ? "far fa-check-square"
                                                    : "far fa-square"
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-icon",
                                      {
                                        staticClass: "mr-0",
                                        staticStyle: { width: "60px" }
                                      },
                                      [
                                        _c(
                                          "v-chip",
                                          {
                                            staticClass:
                                              "lighten-2 grey--text text--darken-3 flag-short-code",
                                            attrs: { color: "info", small: "" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(flag.shortCode) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-content", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "grey--text text--darken-3 body-2 d-inline-block text-truncate",
                                          staticStyle: { width: "100px" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(flag.name) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.filters.shift.flags,
                          callback: function($$v) {
                            _vm.$set(_vm.filters.shift, "flags", $$v)
                          },
                          expression: "filters.shift.flags"
                        }
                      }),
                      _c(
                        "v-card",
                        {
                          staticClass: "grey lighten-5 mt-3 pa-4",
                          attrs: { outlined: "" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "grey--text text--darken-3 font-weight-medium body-2 mb-2"
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("labels.showResultsContaining")
                                  ) +
                                  "\n            "
                              )
                            ]
                          ),
                          _c(
                            "v-radio-group",
                            {
                              staticClass:
                                "mt-0 pt-0 extra-dense-radio flags-op",
                              attrs: {
                                dense: "",
                                "hide-details": "",
                                mandatory: ""
                              },
                              model: {
                                value: _vm.filters.shift.flagsOp,
                                callback: function($$v) {
                                  _vm.$set(_vm.filters.shift, "flagsOp", $$v)
                                },
                                expression: "filters.shift.flagsOp"
                              }
                            },
                            [
                              _c("v-radio", {
                                staticClass: "mb-2 any-flag",
                                attrs: {
                                  color: "secondary",
                                  label: _vm.$t("labels.anySelectedFlags"),
                                  value: "or"
                                }
                              }),
                              _c("v-radio", {
                                staticClass: "all-flag",
                                attrs: {
                                  color: "secondary",
                                  label: _vm.$t("labels.allSelectedFlags"),
                                  value: "and"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-select", {
                        staticClass: "inline shift-types",
                        attrs: {
                          dense: "",
                          "hide-details": "",
                          "item-text": "name",
                          "item-value": "id",
                          items: _vm.shiftTypes,
                          label:
                            _vm.filters.shift.types.length === 0
                              ? _vm.$t("labels.allShiftTypes")
                              : _vm.$t("labels.shiftTypes"),
                          "menu-props": { top: false, offsetY: true },
                          multiple: "",
                          outlined: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend-item",
                            fn: function() {
                              return [
                                _c(
                                  "v-list-item",
                                  {
                                    attrs: { ripple: "" },
                                    on: {
                                      mousedown: function($event) {
                                        $event.preventDefault()
                                      },
                                      click: function($event) {
                                        _vm.filters.shift.types = []
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list-item-action",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              color: "secondary",
                                              small: ""
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.filters.shift.types
                                                    .length === 0
                                                    ? "far fa-check-square"
                                                    : "far fa-square"
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t("labels.allShiftTypes")
                                              ) +
                                              "\n                  "
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("v-divider")
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "selection",
                            fn: function(ref) {
                              var index = ref.index
                              var item = ref.item
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      item.name +
                                        (index ===
                                        _vm.filters.shift.types.length - 1
                                          ? ""
                                          : ",")
                                    ) +
                                    "\n            "
                                )
                              ]
                            }
                          },
                          {
                            key: "item",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "v-list-item-action",
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: { color: "secondary", small: "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.filters.shift.types.includes(
                                                item.id
                                              )
                                                ? "far fa-check-square"
                                                : "far fa-square"
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.name) +
                                          "\n                "
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.filters.shift.types,
                          callback: function($$v) {
                            _vm.$set(_vm.filters.shift, "types", $$v)
                          },
                          expression: "filters.shift.types"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-select", {
                        staticClass: "inline shift-mode",
                        attrs: {
                          dense: "",
                          hint: _vm.selectedShiftModeHint,
                          "item-text": "name",
                          "item-value": "id",
                          items: _vm.shiftModes,
                          "menu-props": { top: false, offsetY: true },
                          outlined: "",
                          "persistent-hint": ""
                        },
                        model: {
                          value: _vm.filters.shift.mode,
                          callback: function($$v) {
                            _vm.$set(_vm.filters.shift, "mode", $$v)
                          },
                          expression: "filters.shift.mode"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            { staticClass: "pa-4 mb-4", attrs: { outlined: "" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-switch", {
                        staticClass:
                          "pt-0 mt-0 ml-1 d-inline-block event-enabled",
                        attrs: {
                          color: "success",
                          dense: "",
                          "hide-details": "",
                          inset: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "mr-4 body-2 grey--text text--darken-3",
                                    attrs: { title: _vm.$t("labels.event") }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$tc("labels.event", 1)) +
                                        "\n              "
                                    )
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.filters.event.enabled,
                          callback: function($$v) {
                            _vm.$set(_vm.filters.event, "enabled", $$v)
                          },
                          expression: "filters.event.enabled"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-select", {
                        staticClass: "inline event-type",
                        attrs: {
                          dense: "",
                          "hide-details": "",
                          "item-text": "name",
                          "item-value": "id",
                          items: _vm.eventTypes,
                          label:
                            _vm.filters.event.types.length === 0
                              ? _vm.$t("labels.allEventTypes")
                              : _vm.$t("labels.eventTypes"),
                          "menu-props": { top: false, offsetY: true },
                          multiple: "",
                          outlined: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend-item",
                            fn: function() {
                              return [
                                _c(
                                  "v-list-item",
                                  {
                                    attrs: { ripple: "" },
                                    on: {
                                      mousedown: function($event) {
                                        $event.preventDefault()
                                      },
                                      click: function($event) {
                                        _vm.filters.event.types = []
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list-item-action",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              color: "secondary",
                                              small: ""
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.filters.event.types
                                                    .length === 0
                                                    ? "far fa-check-square"
                                                    : "far fa-square"
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$t("labels.allEventTypes")
                                              ) +
                                              "\n                  "
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("v-divider")
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "selection",
                            fn: function(ref) {
                              var index = ref.index
                              var item = ref.item
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      item.name +
                                        (index ===
                                        _vm.filters.event.types.length - 1
                                          ? ""
                                          : ",")
                                    ) +
                                    "\n            "
                                )
                              ]
                            }
                          },
                          {
                            key: "item",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "v-list-item-action",
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: { color: "secondary", small: "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.filters.event.types.includes(
                                                item.id
                                              )
                                                ? "far fa-check-square"
                                                : "far fa-square"
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.name) +
                                          "\n                "
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.filters.event.types,
                          callback: function($$v) {
                            _vm.$set(_vm.filters.event, "types", $$v)
                          },
                          expression: "filters.event.types"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "filter-actions", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "reset-filter",
                  attrs: { color: "primary", outlined: "", width: "100%" },
                  on: { click: _vm.reset }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("labels.reset")) + "\n      "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "apply-filter",
                  attrs: { color: "secondary", width: "100%" },
                  on: { click: _vm.apply }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("labels.apply")) + "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }