<template>
  <v-container
    v-if="view === 'main'"
    class="px-8 py-0 schedule-help"
  >
    <v-list-item
      class="pa-0 mb-5"
    >
      <v-list-item-icon>
        <v-icon> fal fa-info-circle</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="pb-0">
        <span class="page-title panel-title">{{ $t('labels.information') }}</span>
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="caption"
          v-html="$t('descriptions.togglePanel', { key: toggleKey })"
        />
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-container
      class="schedule-help-content pa-0"
      :style="infoStyle"
    >
      <template v-if="!$store.getters['account/isStaff']()">
        <span class="d-block subtitle-2">
          {{ $t('labels.keyboardShortcuts') }}
        </span>
        <div
          class="caption grey--text text--darken-3"
          v-html="$t('descriptions.obligatoryShiftShortcut', { key: shiftKey})"
        />
        <v-list>
          <template v-for="(shift) in shiftTypes">
            <v-list-item
              :key="`shift${shift.id}`"
              class="keyboard-shortcut pl-0"
              :title="shift.description || shift.name"
            >
              <v-list-item-icon
                class="d-inline-block help-icon text-center mr-3 my-0"
                :style="{'align-self': 'center'}"
              >
                <ScheduleSymbol
                  :symbol="shift.styles.web"
                  :entity="shift"
                />
              </v-list-item-icon>
              <v-list-item-content
                class="body-2 text-truncate d-inline-block"
              >
                {{ shift.description || shift.name }}
              </v-list-item-content>
              <v-list-item-action v-if="shift.styles.web.keyboardShortcut">
                <v-chip
                  v-for="(key) in getKeys(shift.styles.web.keyboardShortcut)"
                  :key="key"
                  class="ma-2  text-capitalize"
                  color="secondary"
                  label
                  small
                >
                  {{ key }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="`shiftdiv${shift.id}`" />
          </template>
        </v-list>
      </template>
      <span class="d-block subtitle-2">
        {{ $t('labels.legend') }}
      </span>
      <v-list>
        <v-list-item
          class="legend pl-0"
          :title="$t('labels.availableShift')"
        >
          <v-list-item-icon class="d-inline-block help-icon text-center mr-3 mt-3">
            <ScheduleSymbol :symbol="getSymbolStyle('available')" />
          </v-list-item-icon>
          <v-list-item-content class="body-2">
            {{ $t('labels.availableShift') }}
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item
          class="legend pl-0"
          :title="$t('labels.obligatoryShift')"
        >
          <v-list-item-icon class="d-inline-block help-icon text-center mr-3 mt-3">
            A*
          </v-list-item-icon>
          <v-list-item-content class="body-2">
            {{ $t('labels.obligatoryShift') }}
            <v-tooltip
              max-width="300px"
              top
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  class="obligatory-help ml-2"
                  color="info"
                  x-small
                  v-bind="attrs"
                  v-on="on"
                >
                  fal fa-question-circle
                </v-icon>
              </template>
              <span class="body-2">
                {{ $t('descriptions.obligatoryShiftHelp') }}
              </span>
            </v-tooltip>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item
          class="legend pl-0"
          :title="$t('labels.nonDutyShift')"
        >
          <v-list-item-icon class="d-inline-block help-icon text-center mr-3 mt-3">
            <span class="non-duty" />
            A
          </v-list-item-icon>
          <v-list-item-content class="body-2">
            {{ $t('labels.nonDutyShift') }}
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item
          class="legend pl-0"
          :title="$tc('labels.event', 2)"
        >
          <v-list-item-icon class="d-inline-block help-icon text-center mr-3 mt-3">
            <ScheduleSymbol :symbol="{ symbolType: 'bar', color: '#D9CAFD', style: { height: '5px !important', width: '20px !important' } }" />
          </v-list-item-icon>
          <v-list-item-content class="body-2">
            {{ $tc('labels.event', 2) }} ({{ $t('labels.nonDuty') }})
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item
          class="legend pl-0 flag"
          :title="$tc('labels.flag', 1)"
        >
          <v-list-item-icon class="d-inline-block help-icon text-center mr-3 pl-3">
            <div class="grid-cell-dashed">
              A
            </div>
            <v-icon
              color="info lighten-1"
              size="6"
            >
              fas fa-flag
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content class="body-2">
            {{ $tc('labels.flag', 1) }}
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              class="view-flags"
              color="primary"
              outlined
              small
              @click="setView('flags')"
            >
              {{ $t('labels.viewList') }}
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider />
        <v-list-item
          class="legend pl-0 modified"
          :title="$t('labels.modifiedByManagement')"
        >
          <v-list-item-icon class="d-inline-block help-icon text-center mr-3 pl-3">
            <div class="grid-cell-dashed">
              A
            </div>
            <v-icon
              color="nb-gold"
              size="6"
            >
              fas fa-pencil
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content class="body-2">
            {{ $t('labels.modifiedByManagement') }}
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item
          class="legend pl-0"
          :title="$t('labels.consecutiveShiftsExcessive')"
        >
          <v-list-item-icon class="d-inline-block help-icon text-center mr-3 pl-3">
            <div class="grid-cell-dashed consecutive-shifts">
              A
            </div>
          </v-list-item-icon>
          <v-list-item-content class="body-2">
            {{ $t('labels.consecutiveShiftsExcessive') }}
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item
          class="legend pl-0"
          :title="`${$t('labels.overtime')}/${$t('labels.undertime')}`"
        >
          <v-list-item-icon class="d-inline-block help-icon text-center mr-3 pl-3">
            <div class="grid-cell-dashed extra-shifts">
              8
            </div>
          </v-list-item-icon>
          <v-list-item-content class="body-2">
            {{ `${$t('labels.overtime')}/${$t('labels.undertime')}` }}
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item
          class="legend pl-0"
          :title="$tc('labels.holiday', 1)"
        >
          <v-list-item-icon class="d-inline-block help-icon text-center mr-3 pl-3">
            <div
              class="grid-cell-dashed holiday"
              :style="holidayStyle"
            >
              A
            </div>
          </v-list-item-icon>
          <v-list-item-content class="body-2">
            {{ $tc('labels.holiday', 1) }}
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item
          class="legend pl-0 request"
          :title="$t('labels.newRequest')"
        >
          <v-list-item-icon class="d-inline-block help-icon text-center mr-3 pl-3">
            <div class="grid-cell-dashed">
              A
            </div>
            <v-icon
              color="warning"
              size="6"
            >
              fas fa-circle
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content class="body-2">
            {{ $t('labels.newRequest') }}
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item
          class="legend pl-0 request"
          :title="$t('labels.newRequest')"
        >
          <v-list-item-icon class="d-inline-block help-icon text-center mr-3 pl-3">
            <div class="grid-cell-error">
              A
            </div>
          </v-list-item-icon>
          <v-list-item-content class="body-2">
            {{ $t('labels.conflicts') }}
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item
          class="legend pl-0"
          :title="$t('labels.canceled')"
        >
          <v-list-item-icon class="d-inline-block help-icon text-center mr-3 mt-3">
            <span class="py-2 grid-cell">
              <span class="non-duty canceled" />
              <span
                class="shift-status"
                :style="{ ...getSymbolStyle('canceled')['css'] }"
              >
                A
              </span>
            </span>
          </v-list-item-icon>
          <v-list-item-content class="body-2">
            {{ $t('labels.canceled') }}
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item
          class="legend pl-0"
          :title="$t('labels.nonProductive')"
        >
          <v-list-item-icon class="d-inline-block help-icon text-center mr-3 mt-3">
            <span class="py-2 grid-cell">
              <span
                class="shift-status"
                :style="{ ...getSymbolStyle('canceled')['css'] }"
              >
                A
              </span>
            </span>
          </v-list-item-icon>
          <v-list-item-content class="body-2">
            {{ $t('labels.nonProductive') }}
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item
          class="legend pl-0"
          :title="$t('labels.onCall')"
        >
          <v-list-item-icon class="d-inline-block help-icon text-center mr-3 mt-3">
            <span class="py-2 grid-cell">
              <span
                class="shift-status"
                :style="{ ...getSymbolStyle('onCall')['css'] }"
              >
                A
              </span>
            </span>
          </v-list-item-icon>
          <v-list-item-content class="body-2">
            {{ $t('labels.onCall') }}
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-list-item
          class="legend pl-0"
          :title="$t('labels.sitter')"
        >
          <v-list-item-icon class="d-inline-block help-icon text-center mr-3 mt-3">
            <span class="py-2 grid-cell">
              <span
                class="shift-status"
                :style="{ ...getSymbolStyle('sitter')['css'] }"
              >
                A
              </span>
            </span>
          </v-list-item-icon>
          <v-list-item-content class="body-2">
            {{ $t('labels.sitter') }}
          </v-list-item-content>
        </v-list-item>
        <v-divider />
      </v-list>
    </v-container>
  </v-container>
  <v-container
    v-else-if="view === 'flags'"
    class="px-8 py-0 schedule-help"
  >
    <v-list-item
      class="pa-0 mb-5"
    >
      <v-list-item-action>
        <v-btn
          icon
          @click="setView('main')"
        >
          <v-icon>fal fa-arrow-circle-left</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-content>
        <span class="page-title panel-title">{{ $tc('labels.flag', 2) }}</span>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-container
      class="schedule-help-content pa-0"
      :style="infoStyle"
    >
      <v-list>
        <template v-for="(flag) in flags">
          <v-list-item
            :key="`flag${flag.id}`"
            class="keyboard-shortcut pl-0"
            :title="flag.description || flag.name"
          >
            <v-list-item-icon class="d-inline-block help-icon text-center mr-3 mt-3">
              <v-chip
                class="lighten-2 grey--text text--darken-3 flag-short-code mb-1"
                color="info"
                small
              >
                {{ flag.shortCode }}
              </v-chip>
            </v-list-item-icon>
            <v-list-item-content
              class="body-2 text-truncate d-inline-block"
            >
              {{ flag.name }}
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="`flagdiv${flag.id}`" />
        </template>
      </v-list>
    </v-container>
  </v-container>
</template>

<script>
import _ from 'lodash';
import ScheduleSymbol from '@/views/scheduling/ScheduleSymbol';

export default {
  components: {
    ScheduleSymbol
  },
  data: () => {
    // This regular expression is from Mousetrap, but it does not expose it publicly.
    const metaKey = /Mac|iPod|iPhone|iPad/.test(navigator.platform) ? 'Cmd' : 'Ctrl';
    const keyClass = 'px-2 v-chip v-chip--label theme--light v-size--x-small secondary white--text';
    const toggleKey = [
      `<span class="${keyClass} ml-0 mr-1">${metaKey}</span>`,
      `<span class="${keyClass} mr-0">/</span>`
    ].join('');
    const shiftKey = `<span class="${keyClass} ml-0 mr-0">Shift</span>`;
    return {
      height: 500,
      metaKey,
      shiftKey,
      toggleKey,
      view: 'main'
    };
  },
  computed: {
    holidayStyle () {
      return {
        'background-color': _.get(this.$store.state.org, ['settings', 'scheduling', 'holidays', 'styles', 'web', 'bgColor'], '')
      };
    },
    infoStyle () {
      return {
        'height': `${this.height}px`,
        'overflow-y': 'auto'
      };
    },
    eventTypes () {
      return _.sortBy(this.$store.state.org.eventTypes, [ function (e) { return e.description || e.name; } ]);
    },
    flags () {
      return _.sortBy(this.$store.state.org.flags, ['name']);
    },
    shiftTypes () {
      return this.$store.state.org.shiftTypes.filter(shift => shift.partakeInScheduling);
    }
  },
  mounted: function () {
    this.updateHeight();
    window.addEventListener('resize', _.debounce(this.updateHeight, 500));
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.updateHeight);
  },
  methods: {
    getKeys (shortcut) {
      const keys = shortcut.split('+');
      for (let i = 0, len = keys.length; i < len; i++) {
        if (keys[i] === 'meta') {
          keys[i] = this.metaKey;
        }
      }
      return keys;
    },
    getSymbolStyle (symbol) {
      const symbolConfig = _.get(this.$store.state.org.settings.scheduling.symbols, ['shift', 'web', symbol], {});
      const style = {
        ...symbolConfig,
        css: {}
      };
      if (!_.isEmpty(symbolConfig)) {
        style.css['background-color'] = symbolConfig.bgColor;
        style.css['color'] = symbolConfig.color;
      }
      return style;
    },
    setView (view) {
      this.view = view;
    },
    updateHeight () {
      const el = document.getElementsByClassName('side-panel')[0];
      const dailySummaryContent = document.getElementsByClassName('schedule-help-content')[0];
      if (el && dailySummaryContent) {
        this.height = el.clientHeight - dailySummaryContent.getBoundingClientRect().top;
      }
    }
  }
};
</script>

<style lang="scss">
.schedule-help {
  .holiday {
    border: 1px dashed map-get($grey, 'lighten-1');
    height: 34px;
    width: 23px;
  }

  .flag-short-code {
    justify-content: center;
    padding: 0px;
    width: 43px !important;
  }

  .grid-cell {
    background-color: #FFF;
    border: 1px solid map-get($grey, 'lighten-3');
    height: 36px;
    width: 26px;

    &.consecutive-shifts {
      background-color: #FDE3E3;
      border-style: dashed;
    }

    &.extra-shifts {
      background-color: #F5F5F5;
      border-style: dashed;
      color: $error;
    }
  }

  .grid-cell-dashed {
    border: 1px dashed #bdbdbd;
    height: 36px;
    width: 26px;
    line-height: 34px;

    &.consecutive-shifts {
      background-color: #FDE3E3;
    }

    &.extra-shifts {
      background-color: #F5F5F5;
      color: $error;
    }
  }

  .grid-cell-error {
    border: 1px solid $error;
    height: 36px;
    width: 26px;
    line-height: 34px;
  }

  .flag {
    .v-list-item__icon {
      position: relative;
      .v-icon {
        position: absolute;
        top: 2px;
        left: 14px;
      }
    }
  }

  .modified {
    .v-list-item__icon {
      position: relative;
      .v-icon {
        position: absolute;
        top: 2px;
        right: 14px;
      }
    }
  }

  .non-duty {
    border-top: 1px solid map-get($grey, 'darken-3');
    display: inline-block;
    left: 10px;
    position: absolute;
    top: 24px;
    -webkit-transform: rotate(20deg);
    -moz-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    -o-transform: rotate(20deg);
    transform: rotate(20deg);
    width: 30px;
    &.canceled {
      left: 14px;
      width: 22px;
    }
  }

  .request {
    .v-list-item__icon {
      position: relative;
      .v-icon {
        position: absolute;
        top: 2px;
        right: 22px;
      }
    }
  }

  .shift-status {
    border-radius: 2px;
    margin: 0px 1px;
    padding: 0px 7px !important;
  }

  .keyboard-shortcut, .legend {
    height: 48px;

    .v-chip {
      font-family: $monospaced-font-family;
    }
  }

  .help-icon {
    margin-top: 6px;
    width: 50px;
  }

  .obligatory-help {
    flex: none !important;
  }
}
</style>
